import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MessengerType } from 'src/common/interfaces';
import { extendedMessengerName } from 'src/common/utils/extendMessengerName';
import { Button } from 'src/ui/Button/Button';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'src/ui/AlertDialog/AlertDialog';
import { FaSpinner } from 'react-icons/fa6';
import { cn } from 'src/common/utils';
import { FaTimes } from 'react-icons/fa';

interface RemoveAlertProps extends React.ComponentProps<typeof AlertDialog> {
  isLoading?: boolean;
  onRemove?: () => void;
  messengerType: MessengerType;
  isTheLast?: boolean;
}

export const RemoveAlert = ({
  open,
  onOpenChange,
  children,
  isLoading,
  onRemove,
  messengerType,
  isTheLast,
}: RemoveAlertProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent
        className={cn('max-w-[600px]', {
          'max-w-[calc(100vw-30px)] md:max-w-[650px] !rounded-[24px] p-[30px] md:p-[100px] md:pt-[30px] gap-0':
            isTheLast,
        })}
      >
        {!isTheLast ? (
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('communicationChannels.alert.title_start')}
              {extendedMessengerName[messengerType]}
              {t('communicationChannels.alert.title_end')}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t('communicationChannels.alert.description')}
            </AlertDialogDescription>
          </AlertDialogHeader>
        ) : (
          <AlertDialogHeader>
            <Button
              className={cn(
                'font-semibold w-fit h-fit p-0 m-0 text-grey-500 ml-auto -mr-[10px] md:-mr-[70px] mb-[15px]',
              )}
              onClick={() => onOpenChange?.(false)}
            >
              <FaTimes className='w-[22px] h-[22px]' />
            </Button>
            <h1 className='text-[64px] text-center'>⚠️</h1>
            <AlertDialogTitle className='text-center font-bold text-[24px]'>
              {t('communicationChannels.alert.lastChannelTitle')}
            </AlertDialogTitle>
            <AlertDialogDescription className='text-center text-[16px] leading-[24px] mt-[16px]'>
              {t('communicationChannels.alert.warning')}
            </AlertDialogDescription>
          </AlertDialogHeader>
        )}
        {!isTheLast ? (
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isLoading}>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              asChild
              onClick={e => {
                e.preventDefault();
                onRemove?.();
              }}
              disabled={isLoading}
            >
              <Button variant={'danger'} className='!px-[18px] !py-[13px]'>
                {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                {t('communicationChannels.remove')}
              </Button>
            </AlertDialogAction>
          </AlertDialogFooter>
        ) : (
          <AlertDialogFooter className='mt-[44px] !justify-center'>
            <AlertDialogAction
              className='font-semibold px-[13px] py-[18px] h-[50px]'
              asChild
              onClick={e => {
                e.preventDefault();
                onRemove?.();
              }}
              disabled={isLoading}
            >
              <Button variant={'danger'}>
                {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                {t('communicationChannels.alert.disconnect')}
              </Button>
            </AlertDialogAction>
            <AlertDialogCancel
              disabled={isLoading}
              className='font-semibold px-[13px] py-[18px] h-[50px] hover:opacity-90'
            >
              {t('communicationChannels.alert.keep')}
            </AlertDialogCancel>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};
