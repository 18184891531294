import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessengerType } from 'src/common/interfaces';
import { extendedMessengerName } from 'src/common/utils/extendMessengerName';
import { useDispatch } from 'react-redux';
import { removeWhatsappSettings } from 'src/common/api/channels-settings';
import { useMutation } from '@tanstack/react-query';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { RemoveAlert } from './RemoveAlert';

const RemoveWhatsappAlert: React.FC<{ isTheLast?: boolean }> = ({ children, isTheLast }) => {
  const [open, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation({
    mutationFn: removeWhatsappSettings,
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { whatsappSettings: data } } }));
    },
    onSettled: () => {
      setIsOpen(false);
    },
  });

  return (
    <RemoveAlert
      open={open}
      onOpenChange={setIsOpen}
      messengerType={MessengerType.WHATSAPP}
      isLoading={isLoading}
      onRemove={mutate}
      isTheLast={isTheLast}
    >
      {children}
    </RemoveAlert>
  );
};

export { RemoveWhatsappAlert };
