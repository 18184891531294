import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaSpinner, FaTrashAlt } from 'react-icons/fa';
import { FaArrowRotateRight } from 'react-icons/fa6';
import { v4 as uuidv4 } from 'uuid';

import { FileInputButton } from '../FileInputButton/FileInputButton';
import { cn } from 'src/common/utils/cn';
import Cropper from 'cropperjs';
import { Dialog, DialogContent } from '../Dialog/Dialog';
import { Button } from '../Button/Button';
import 'cropperjs/dist/cropper.css';
import { dataUrlToFile } from 'src/common/utils';
import { FaPencil } from 'react-icons/fa6';
import { FaSearchMinus, FaSearchPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export interface CropperModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  url: string;
  onApply: (imageUrl: string) => void;
  mode?: 'square' | 'rectangle';
}

export const CropperModal: React.FC<CropperModalProps> = ({
  isOpen,
  setIsOpen,
  url,
  onApply,
  mode = 'square',
}) => {
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [cropperLoaded, setCropperLoaded] = useState<boolean>(false);

  const { t } = useTranslation();

  const initializeCropper = (node: HTMLImageElement | null) => {
    if (node && !cropper) {
      setCropper(
        new Cropper(node, {
          aspectRatio: mode === 'square' ? 1 / 1 : 2 / 1,
          viewMode: 1,
          checkOrientation: false,
          checkCrossOrigin: true,
          responsive: true,
          scalable: true,
          ready: () => {
            setCropperLoaded(true);
          },
          minContainerWidth: 300,
          minContainerHeight: 300,
        }),
      );
    }
  };

  const handleApply = () => {
    if (cropper) {
      const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
      onApply(croppedDataUrl);
      setCropper(null);
    }
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      cropper?.destroy();
      setCropper(null);
      setIsOpen(false);
      setCropperLoaded(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className='xs:max-w-[90vw] max-w-[650px] rounded-[24px]'>
        <h1 className='text-[24px] font-semibold leading-[32px] text-center mb-[14px] md:mb-[23px] text-grey-900'>
          {t('common.cropper')}
        </h1>
        <div className='grow'>
          {!cropperLoaded ? (
            <div className='m-auto w-fit'>
              <FaSpinner className='animate-spin w-10 h-10 text-primary mr-2' />
            </div>
          ) : null}
          {isOpen ? (
            <img
              ref={node => {
                initializeCropper(node);
              }}
              className='hidden'
              src={url}
              alt='Cropper'
            />
          ) : null}
        </div>
        {cropperLoaded ? (
          <div className='flex justify-between gap-[15px] items-center mt-[20px] md:mt-[30px]'>
            <div className='flex gap-[8px]'>
              <FileInputButton onClickHandle={() => cropper?.zoom(1)} icon={FaSearchPlus} />
              <FileInputButton onClickHandle={() => cropper?.zoom(-1)} icon={FaSearchMinus} />
              <FileInputButton
                onClickHandle={() => cropper?.rotate(90)}
                icon={FaArrowRotateRight}
              />
            </div>
            <Button variant='primary' onClick={handleApply}>
              Apply
            </Button>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
export interface FileInputProps {
  children: React.ReactNode;
  tailwindClasses?: string;
  defaultUrl?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, url: string) => void;
  onDelete?: (url: string | null) => void;
  className?: string;
  validExtesnions?: string[];
  onCropperApply?: (file: File, url: string) => void;
  cropperMode?: 'square' | 'rectangle';
  sizes?: string;
}

export const FileInput: React.FC<FileInputProps> = ({
  tailwindClasses,
  children,
  defaultUrl,
  onDelete,
  onChange,
  className,
  validExtesnions = ['jpg', 'jpeg', 'png'],
  onCropperApply,
  cropperMode = 'square',
  sizes,
}) => {
  const [url, setUrl] = useState<string | null>(defaultUrl ?? null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const id = uuidv4();

  const isValidExtension = (fileName: string) => {
    return new RegExp('(' + validExtesnions.join('|').replace(/\./g, '\\.') + ')$').test(fileName);
  };

  const onApply = async (url: string) => {
    const fileExtension =
      inputFile.current &&
      inputFile.current?.files?.length &&
      inputFile.current.files[0].name.split('.').pop();
    const urlExtension = defaultUrl?.split('.').pop();

    const extension = fileExtension || urlExtension;

    if (extension) {
      setUrl(url);
      setIsModalOpened(false);

      const file = await dataUrlToFile(url, 'image', extension);

      if (file) {
        onCropperApply?.(file, url);
      }
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      if (isValidExtension(e.target.files[0].name)) {
        const url = URL.createObjectURL(e.target.files[0]);
        setUrl(url);
        setError(null);

        if (onChange) {
          onChange(e, url);
        }
      } else {
        setError(`Uploaded file didn't have allowed extension(s): ${validExtesnions.join()}.`);
      }
    }
  };

  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = '';
      setUrl(null);
    }
    if (onDelete) {
      onDelete(url);
    }
  };

  return (
    <div className={cn('file-upload min-w-fit', className)}>
      <input
        type='file'
        name='file'
        id={id}
        className='hidden'
        accept='image/png, image/jpeg'
        onChange={handleOnChange}
        ref={inputFile}
      />
      {!url ? (
        <label
          htmlFor={id}
          className={`
            ${tailwindClasses}
            mx-auto
            w-[192px] h-[192px]
            bg-[#F5F8FA66] flex flex-col justify-center items-center
            block border-dashed border-[1px] border-[#B5B5C3] rounded-[9px]
            hover:bg-[#F1FAFF] hover:border-[#00A3FF]
            ${sizes}
          `}
        >
          {children}
        </label>
      ) : (
        <div
          className={`block w-[192px] h-[192px] mx-auto relative border-dashed border-[1px] border-[#B5B5C3] rounded-[9px] flex flex-col justify-center overflow-hidden ${sizes}`}
        >
          <div className='absolute top-[10px] right-[10px] z-10'>
            <FileInputButton onClickHandle={handleReset} icon={FaTrashAlt} />
          </div>
          <div className='absolute top-[10px] left-[10px] z-10'>
            <FileInputButton onClickHandle={() => setIsModalOpened(true)} icon={FaPencil} />
          </div>
          <img src={url} alt='Preview' />
        </div>
      )}
      {url ? (
        <CropperModal
          mode={cropperMode}
          url={url}
          isOpen={isModalOpened}
          setIsOpen={setIsModalOpened}
          onApply={onApply}
        />
      ) : null}
    </div>
  );
};
