import React, { useMemo, useRef, useState } from 'react';
import { OnboardingQuestion, OnboardingWrapper } from '../Common';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { paymentSchema } from 'src/common/utils/schema/payments.schema';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PaymentsForm } from './PaymentsForm';
import { FaStripe } from 'react-icons/fa';
import { AppStateType } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { UserInterface, updateUserLocaly } from 'src/store/user/user.slice';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { useToast } from 'src/ui/Toast/use-toast';
import { setDefaultPayments, updatePaymentSettings } from 'src/common/api/payments';

interface PaymentsLeftProps {}

type Inputs = z.infer<typeof paymentSchema>;

export const PaymentsLeft: React.FC<PaymentsLeftProps> = () => {
  const paymentButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { mutate: update, isLoading: isCommonLoading } = useMutation({
    mutationFn: () => setDefaultPayments(),
    onMutate: () => {
      setIsLoading?.(true);
    },
    onSuccess: (data: UserInterface) => {
      setIsLoading?.(false);
      dispatch(updateUserLocaly(data));
      dispatch(nextStep(1));
    },
    onError: (error: Error) => {
      setIsLoading?.(false);
      toast({ title: error.message, variant: 'destructive' });
    },
  });

  const form = useForm<Inputs>({
    resolver: zodResolver(paymentSchema),
  });

  const { formState } = form;
  const { user } = useSelector((state: AppStateType) => state.user);

  const watcher = useWatch({
    control: form.control,
  });

  console.log(watcher);

  const isFormDisabled = useMemo(() => {
    const hasIncompletedValues = user?.expert?.paymentSettings
      ? Object.entries(user?.expert?.paymentSettings)
          .filter(
            arr =>
              arr[0] !== 'bankToken' &&
              arr[0] !== 'stripeId' &&
              arr[0] !== 'addrLine2' &&
              arr[0] !== 'useCommonAccount',
          )
          .find(value => value[1] === null || value[1] === '')
      : true;

    const hasFormIncompletedValues = Object.entries(watcher)
      .filter(
        arr =>
          arr[0] !== 'bankToken' &&
          arr[0] !== 'stripeId' &&
          arr[0] !== 'addrLine2' &&
          arr[0] !== 'birthday' && 
          arr[0] !== 'useCommonAccount',
      )
      .find(value => value[1] === null || value[1] === '');

    const isBirthdayUncompleted =
      watcher.birthday && Object.entries(watcher.birthday).length !== 0
        ? Object.entries(watcher.birthday).find(
            value => value[1] === null || value[1] === undefined,
          )
        : true;

    return formState.isDirty
      ? !!isBirthdayUncompleted || !!hasFormIncompletedValues
      : !!hasIncompletedValues;
  }, [user?.expert?.paymentSettings, watcher, formState.isDirty]);

  return (
    <>
      <OnboardingWrapper
        headerText='onboarding.payments.header'
        subheaderText='onboarding.payments.subheader'
        onSubmit={() => paymentButtonRef.current?.click()}
        onAlternativeSubmit={() => update()}
        blockNextStep
        icon={FaStripe}
        isLoading={isLoading || isCommonLoading}
        isSubmitDisabled={isLoading || isFormDisabled}
      >
        <PaymentsForm {...form} paymentButtonRef={paymentButtonRef} setIsLoading={setIsLoading} />
      </OnboardingWrapper>
      <OnboardingQuestion i18nKey={t('onboarding.payments.question')} />
    </>
  );
};
