import React from 'react';
import { MessengerTutorial } from '../Onboarding/Subpages/Connection/Tutorials/MessengerTutorial';
import { Button } from 'src/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa6';
import { TutorialPageWrapper } from './TutorialPageWrapper';

export default function MessengerTutorialPage() {
  const navigate = useNavigate();

  return (
    <TutorialPageWrapper>
      <MessengerTutorial>
        <Button onClick={() => navigate(-1)}>
          <FaChevronLeft className='w-6 h-6 text-muted ' />
        </Button>
      </MessengerTutorial>
    </TutorialPageWrapper>
  );
}
