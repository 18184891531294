import React, { type RefObject, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextInput } from 'src/ui/TextInput/TextInput';
import { Card } from 'src/ui/Card/Card';
import { Textarea } from 'src/ui/TextInput/TextInput';
import { useForm, Controller } from 'react-hook-form';
import { updateUserProfile } from 'src/store/user/user.actions';
import { AppStateType } from 'src/store';
import { profileSchema } from 'src/common/utils/schema/profile.schema';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'src/components/FieldError';
import { FileInput } from 'src/ui/FileInput/FileInput';
import { AvatarPlaceholder } from 'src/components/AvatarPlaceholder';
import { useExpertiseStore } from 'src/store/expertise/useExpertiseStore';

type Inputs = z.infer<typeof profileSchema>;

type AboutCardProps = {
  aboutButtonRef: RefObject<HTMLButtonElement>;
  file: File | 'DELETED' | null;
  setFile: React.Dispatch<React.SetStateAction<File | 'DELETED' | null>>;
} & ReturnType<typeof useForm<Inputs>>;

const AboutCard = ({
  aboutButtonRef,
  handleSubmit,
  reset,
  control,
  formState: { errors },
  file,
  setFile,
}: AboutCardProps) => {
  const { data: expertise } = useExpertiseStore();
  const { user, userToken } = useSelector((state: AppStateType) => state.user);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    reset({
      ...user,
      avatarUrl: user?.avatarUrl,
      description: user?.expert?.description,
    });
  }, [user]);

  const cards = useMemo(() => {
    return (
      expertise?.map(({ expertiseId, translation }) => ({
        value: expertiseId,
        label: translation,
      })) ?? []
    );
  }, [expertise]);

  if (!user || !userToken) return <></>;

  const onSubmit = (vals: Inputs) => {
    dispatch(
      updateUserProfile({
        token: userToken,
        userAvatar: file,
        userData: {
          id: user.id,
          firstName: vals.firstName,
          lastName: vals.lastName,
          expert: {
            userId: user.id,
            description: vals.description,
          },
        },
      }),
    );
  };

  return (
    <Card variant={'default'} className='w-full px-[20px] md:px-[30px] py-[22px] rounded-[12px]'>
      <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
        <h2 className='text-[18px] font-bold'>{t('profileSettings.about.title')}</h2>
        <div className='mt-[28px] md:mt-[19px] flex gap-[15px] flex-col md:flex-row relative w-full md:gap-[30px]'>
          <Controller
            name='avatarUrl'
            control={control}
            render={({ field }) => {
              return (
                <FileInput
                  sizes='md:max-w-[152px] md:max-h-[152px]'
                  onChange={(e, url) => {
                    if (e.target.files?.[0]) setFile(e.target.files?.[0]);
                    field.onChange(url);
                  }}
                  onCropperApply={(file, url) => {
                    setFile(file);
                    field.onChange(url);
                  }}
                  onDelete={() => {
                    setFile('DELETED');
                    field.onChange(null);
                  }}
                  defaultUrl={field.value ?? undefined}
                  key={field.value}
                >
                  <AvatarPlaceholder />
                </FileInput>
              );
            }}
          />
          <div className='flex flex-col gap-[15px] grow'>
            <label className='text-[#181C32] font-semibold'>
              {t('profileSettings.about.fullName')} <span className='text-danger'>*</span>
            </label>

            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <TextInput
                  className='h-[50px]'
                  placeholder={t('common.expert.firstName')}
                  type='input'
                  {...field}
                  error={errors.firstName?.message}
                />
              )}
            />
            <Controller
              name='lastName'
              control={control}
              render={({ field }) => (
                <TextInput
                  className='h-[50px]'
                  placeholder={t('common.expert.lastName')}
                  type='input'
                  {...field}
                  error={errors.lastName?.message}
                />
              )}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 mt-[24px]'>
          <label className='text-[#181C32] font-semibold'>
            {t('common.expert.description')} <span className='text-danger'>*</span>
          </label>
          <div className='flex flex-col gap-2'>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <Textarea
                  error={errors.description?.message}
                  placeholder={t('common.expert.description')}
                  {...field}
                  limit={1000}
                  className='h-[365px] md:h-[250px]'
                />
              )}
            />
          </div>
        </div>
        <button className='hidden' ref={aboutButtonRef} />
      </form>
    </Card>
  );
};

export { AboutCard };
