import React, { useEffect, useMemo } from 'react';
import * as z from 'zod';
import { Controller, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from 'src/ui/Label/Label';
import { TextInput, Textarea } from 'src/ui/TextInput/TextInput';
import { MessengerIcon } from 'src/ui/MessengerIcon/MessengerIcon';
import { MessengerType } from 'src/common/interfaces';
import { useTranslation } from 'react-i18next';
import {
  MessengerSettingsInputs,
  messengerSettingsSchema,
} from 'src/common/utils/schema/channels-settings.schema';
import { FieldError } from 'src/components/FieldError';
import { useDispatch, useSelector } from 'react-redux';
import { reset, selectUser, updateUserLocaly } from 'src/store/user/user.slice';
import { setMessengerSettings } from 'src/common/api/channels-settings';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'src/ui/Button/Button';
import { Caption } from 'src/ui/Caption/Caption';
import { useScreenDetect, useSearchParams } from 'src/common/hooks';
import { FaCircleInfo, FaSpinner } from 'react-icons/fa6';
import { catchError } from 'src/common/utils/catchError';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { useTutorialNavigation } from 'src/common/hooks/useTutorialNavigations';
import { CopyInput } from 'src/ui/CopyInput/CopyInput';
import { nextStep } from 'src/store/onboarding/onboarding.slice';

const MessengerForm = () => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { searchParams, setSearchParams, setNewSearchParams } = useSearchParams();

  const { isTargetScreen: isTablet } = useScreenDetect('laptop-xs');

  const { showTutorialInformation } = useTutorialNavigation();

  const navigate = useNavigate();

  const {
    control,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<MessengerSettingsInputs>({
    resolver: zodResolver(messengerSettingsSchema),
  });

  const { mutate: saveMessengerSettings, isLoading } = useMutation({
    mutationFn: async (messengerSettings: MessengerSettingsInputs) => {
      return setMessengerSettings(messengerSettings);
    },
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { messengerSettings: data } } }));
      handleGoForward();
    },
    onError: e => {
      catchError(e);
    },
  });

  useEffect(() => {
    reset({
      pageId: user?.expert?.settings?.messengerSettings?.pageId || '',
      pageAccessToken: user?.expert?.settings?.messengerSettings?.pageAccessToken || '',
    });
  }, [user]);

  const handleGoBack = () => {
    setSearchParams({ channel: '' });
  };

  const handleGoForward = () => {
    dispatch(nextStep(1));
  };

  const onSubmit = (vals: MessengerSettingsInputs) => {
    if (!isDirty) handleGoBack();
    saveMessengerSettings(vals);
  };

  const watcher = useWatch({ control });

  const isFormDisabled = useMemo(() => {
    const { pageAccessToken, pageId } = watcher;

    return isDirty
      ? !pageAccessToken || !pageId
      : !user?.expert?.settings?.messengerSettings?.pageAccessToken ||
          !user?.expert?.settings?.messengerSettings?.pageId;
  }, [watcher, user?.expert?.settings?.instagramSettings, isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
      <div className='space-y-2 w-full'>
        <div className='flex items-center justify-between'>
          <Label htmlFor='pageAccessToken'>
            {t('onboarding.channels.messenger.pageAccessToken')}
          </Label>{' '}
          <Button
            className='text-primary'
            type='button'
            onClick={() => showTutorialInformation(ROUTES.MESSENGER_TUTORIAL, '5')}
          >
            <FaCircleInfo className='h-4 w-4' />
            {t('onboarding.channels.messenger.tip.pageAccessToken')}
          </Button>
        </div>
        <Controller
          name='pageAccessToken'
          control={control}
          render={({ field }) => {
            return (
              <Textarea
                id='pageAccessToken'
                {...field}
                rows={4}
                error={errors.pageAccessToken?.message}
              />
            );
          }}
        />
      </div>
      <div className='space-y-2'>
        <div className='flex items-center justify-between'>
          <Label htmlFor='pageId'>{t('onboarding.channels.messenger.pageId')}</Label>
          <Button
            className='text-primary'
            type='button'
            onClick={() => showTutorialInformation(ROUTES.MESSENGER_TUTORIAL, '5')}
          >
            <FaCircleInfo className='h-4 w-4' />
            {t('onboarding.channels.messenger.tip.pageId')}
          </Button>
        </div>
        <Controller
          name='pageId'
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                id='pageId'
                className='h-[50px]'
                {...field}
                error={errors.pageId?.message}
              />
            );
          }}
        />
      </div>
      <div className='space-y-2'>
        <CopyInput
          subUrl='messenger'
          i18nKey='onboarding.channels.webhook.subheader.messenger'
          subheader={
            <span
              role='button'
              className='cursor-pointer text-primary'
              onClick={() => showTutorialInformation(ROUTES.MESSENGER_TUTORIAL, '4')}
            ></span>
          }
        />
      </div>
      <div className='flex items-center !mt-[30px]'>
        <Button variant={'primary'} disabled={isLoading || isFormDisabled}>
          {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
          {t('onboarding.common.saveButton')}
        </Button>
        <Button
          type='button'
          className='h-[36px] w-[70px] px-[15px] py-[5px] bg-light text-grey-600 rounded-[6px] ml-auto'
          onClick={() => {
            handleGoBack();
          }}
        >
          {t('onboarding.common.backButton')}
        </Button>
      </div>
    </form>
  );
};

export { MessengerForm };
