import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { FaCopy, FaRedo } from 'react-icons/fa';
import { regenerateAiResponse } from 'src/common/api/ai-chat';

import { AiChatFrom } from 'src/common/interfaces/ai-chat';
import { cn, saveChatToStorage } from 'src/common/utils';
import { StoreAiChatMessage, useAiChatStore } from 'src/store/ai-chat/aiChatStore';
import ChatMessage from 'src/ui/ChatMessage/ChatMessage';
import { useToast } from 'src/ui/Toast/use-toast';
import { WritingPlaceholder } from 'src/ui/WritingPlaceholder/WritingPlaceholder';

const AiMessage = ({
  from,
  isLast = true,
  ...props
}: StoreAiChatMessage & {
  isLast?: boolean;
}) => {
  const isFromUser = from === AiChatFrom.USER;
  const { isGenerating } = props;

  return (
    <ChatMessage
      message={
        props.isGenerating ? (
          <WritingPlaceholder
            className='bg-white h-[50px] px-0 max-w-[50px]'
            textClassName='!bg-grey-500'
          />
        ) : (
          props.body
        )
      }
      date={props.createdAt}
      sender={isFromUser ? 'user' : 'assistant'}
      actionButtons={
        !isFromUser &&
        !isGenerating && (
          <ActionButtons
            message={props.body}
            messageId={props.id as number}
            showRegenerate={isLast}
          />
        )
      }
      className={cn(isFromUser ? 'bg-info text-white' : 'bg-white text-black', {
        'py-0': isGenerating,
      })}
      textClassName={isFromUser ? 'text-white' : 'text-black'}
    />
  );
};

const ActionButtons = ({
  message,
  messageId,
  showRegenerate,
}: {
  message: string;
  messageId: number;
  showRegenerate?: boolean;
}) => {
  const { toast } = useToast();
  const { setMessageGeneratingState, refreshAiMessage } = useAiChatStore();

  const { mutate: regenerate, isLoading: isRegenerating } = useMutation({
    mutationFn: regenerateAiResponse,
    onMutate: () => {
      setMessageGeneratingState(messageId);
    },
    onSuccess: data => {
      refreshAiMessage(data);
    },
  });

  return (
    <div className='flex w-full justify-center gap-6 text-grey-500'>
      <FaCopy
        role='button'
        className='w-6 h-6'
        onClick={() => {
          const keys = Object.keys(sessionStorage);
          if (keys[0]) {
            saveChatToStorage(keys[0], message);
          }
          toast({ variant: 'success', title: 'Text copied to your message input with your current client!' });
        }}
      />
      {showRegenerate ? (
        <FaRedo
          role='button'
          className={cn('w-6 h-6', { 'cursor-not-allowed text-grey-300': isRegenerating })}
          onClick={() => {
            if (!isRegenerating) regenerate(messageId);
          }}
        />
      ) : null}
    </div>
  );
};

export { AiMessage };
