import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown, Option } from 'src/ui/DropDownRadix/DropDown';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/ui/Table/Table';
import { TransactionStats } from './TransactionsStats';
import { FaComment, FaDownload, FaInfoCircle, FaUserCircle } from 'react-icons/fa';
import { Tooltip } from 'src/ui/Tooltip/Tooltip';
import { PayStatusCard } from 'src/ui/PayStatusCard/PayStatusCard';
import {
  getTransactions,
  generateInvoice,
  getQuickbooksInvoice,
} from 'src/common/api/transactions';
import { UseMutateFunction, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { ROUTES } from 'src/router/routes';
import { Link, useNavigate } from 'react-router-dom';
import { ChatStatus, FiltersInterface, TransactionStatusEnum } from 'src/common/interfaces';
import { getExpertClients } from 'src/common/api/expert';
import { LiveSearch } from 'src/ui/LiveSearch/LiveSearch';
import {
  MobileSkeletonBody,
  TableSkeletonBody,
  TransactionsSkeleton,
} from './TransactionsSkeleton';
import { CalendarPicker } from 'src/ui/CalendarPicker/CalendarPicker';
import { FaSlidersH } from 'react-icons/fa';
import { Button } from 'src/ui/Button/Button';
import { TabletMenu } from 'src/components/TabletMenu';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { cn } from 'src/common/utils';
import { useOnboardingStatus } from 'src/common/hooks';
import { Avatar, AvatarFallback, AvatarImage } from 'src/ui/Avatar/Avatar';
import { FaCreativeCommonsBy } from 'react-icons/fa';

interface TransactionCardProps {
  id: string;
  chatId: string;
  subscriptionType: string;
  username: string;
  price: number;
  status: TransactionStatusEnum;
  chatStatus: ChatStatus;
  avatar: string | null;
  isCommon: boolean | null;
  getInvoice: UseMutateFunction<
    {
      url: string;
    },
    unknown,
    string,
    unknown
  >;
}

function calculateTotalLength(obj: { [key: string]: any[] }) {
  return Object.values(obj).reduce((totalLength: number, value) => {
    if (Array.isArray(value)) {
      return totalLength + value.length;
    } else {
      return totalLength;
    }
  }, 0);
}

const TransactionCard: React.FC<TransactionCardProps> = ({
  id,
  chatId,
  chatStatus,
  price,
  status,
  subscriptionType,
  username,
  getInvoice,
  avatar,
  isCommon,
}) => {
  const { t } = useTranslation();

  return (
    <div className='py-[15px] flex flex-col gap-[15px] border-b-[1px] border-b-[#E5EAEE]'>
      <div className='flex justify-between'>
        <div className='flex gap-[15px] items-center'>
          <div className='relative rounded-full w-[30px] h-[30px] object-cover'>
            <Avatar className='w-[30px] h-[30px]'>
              {avatar ? <AvatarImage src={avatar} alt={username} className='object-cover' /> : null}
              <AvatarFallback>
                <FaUserCircle className='text-grey w-[30px] h-[30px]' />
              </AvatarFallback>
            </Avatar>
          </div>
          <span className='font-semibold'>{username}</span>
        </div>
        <PayStatusCard variant={status} />
      </div>
      <div>
        <div className='text-left align-middlefont-light uppercase text-[11px] text-grey-600 font-light'>
          {t('transactions.table.optionPrice').split('/')[0]}
        </div>
        <div className='text-[14px] flex items-center gap-[5px]'>
          {t(`onboarding.subscriptions.options.${subscriptionType}`)}
          {isCommon ? (
            <Tooltip
              text={
                'You have used the common payment credentials, so your funds will transfer to the Anamata account'
              }
            >
              <FaCreativeCommonsBy className='text-info' />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className='flex justify-between items-start'>
        <div className='flex flex-col'>
          <div className='flex gap-[8px] items-center text-left align-middlefont-light uppercase text-[11px] text-grey-500 font-light'>
            {t('transactions.table.amount')}
          </div>
          <div className='text-[14px]'>${price}</div>
        </div>
        <div className='flex items-center gap-[15px]'>
          <div>
            <Link to={generateChatLink(`../${ROUTES.CHATS}/${chatId}`, chatStatus)}>
              <Tooltip text={t('transactions.tooltip.chat')}>
                <div className='bg-light py-[12px] px-[12px] text-grey-500 hover:text-primary hover:bg-[#009EF7] hover:bg-opacity-10 rounded-md'>
                  <FaComment />
                </div>
              </Tooltip>
            </Link>
          </div>
          {status === TransactionStatusEnum.PAID ? (
            <div>
              <Tooltip text={t('transactions.tooltip.download')}>
                <div
                  className='bg-light py-[12px] px-[12px] text-grey-500 hover:text-primary hover:bg-[#009EF7] hover:bg-opacity-10 rounded-md'
                  onClick={() => getInvoice(id)}
                >
                  <FaDownload />
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const generateChatLink = (link: string, status: ChatStatus) => {
  return `${link}${status === ChatStatus.CLOSED ? `?chatStatus=${status}` : ''}`;
};

const checkDate = (date?: Date) => {
  if (!date) {
    return undefined;
  }

  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() - timezoneOffset);
  return adjustedDate.toISOString().split('T')[0];
};

export const TransactionsPage = () => {
  const { t, isCompleted } = useOnboardingStatus();
  const { user } = useSelector((state: AppStateType) => state.user);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const navigate = useNavigate();

  const [isSettingsEnabled, setIsSettingsEnabled] = useState<boolean>(false);

  const { data: clients } = useQuery([], () => getExpertClients());
  let renderedKeys: string[] = [];

  const [results, setResults] = useState<{ id: string; name: string; avatar: string | null }[]>();
  const [selectedProfile, setSelectedProfile] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [filters, setFilters] = useState<FiltersInterface>({
    status: undefined,
    subscriptionOption: undefined,
    date: undefined,
    clientId: undefined,
    clientName: undefined,
  });

  type changeHandler = React.ChangeEventHandler<HTMLInputElement>;
  const handleChange: changeHandler = e => {
    const { target } = e;

    if (!target.value.trim()) return setResults([]);

    const filteredValue = clients!.filter(profile =>
      profile.name.toLowerCase().includes(target.value.toLowerCase()),
    );

    setResults(filteredValue);
  };

  const handleOnEnter = (value: string) => {
    onFilterChange('clientName', value);
  };

  const {
    data: receivedPages,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['transactions', filters],
    ({ pageParam = 1 }) => getTransactions(filters, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        return calculateTotalLength(lastPage.data) === 10 ? currentPage + 1 : undefined;
      },
    },
  );

  const onFilterChange = (
    key: 'status' | 'subscriptionOption' | 'date' | 'clientId' | 'clientName',
    value: string | {},
  ) => {
    setFilters(state => ({
      ...state,
      [key]: value,
    }));
  };

  const areFiltersActivated = useMemo(() => Object.values(filters).some(v => !!v), [filters]);

  const {
    payStatuses,
    payments,
  }: {
    payStatuses: Option[];
    payments: Option[];
  } = useMemo(() => {
    const payments = Object.values(
      t('onboarding.subscriptions.data', { returnObjects: true }),
    ) as Option[];
    const statuses = Object.values(t('transactions.status', { returnObjects: true })) as string[];

    // TO DO: adjust for different locales

    return {
      payStatuses: statuses.map(s => ({
        text: s,
        value: s,
      })),
      payments,
    };
  }, [t]);

  const { mutate: getInvoice } = useMutation({
    mutationFn: (id: string) => generateInvoice(id),
    onSuccess: data => {
      window.open(data.url, '__blank');
    },
  });

  const { mutate: getQuickbookSheet } = useMutation({
    mutationFn: (filters: FiltersInterface) => getQuickbooksInvoice(filters),
    onSuccess: data => {
      const url = window.URL.createObjectURL(data);
      if (ref.current) {
        const a = ref.current;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);

        a.href = '';
      }
    },
  });

  if (
    (!receivedPages?.pages || !Object.keys(receivedPages?.pages[0].data).length) &&
    !areFiltersActivated
  ) {
    return (
      <main
        className={cn('w-full py-[15px] px-[15px] bg-white md:mb-0 md:px-[30px] md:bg-light', {
          'mb-[100px]': isCompleted,
          'mb-[150px]': !isCompleted,
        })}
      >
        <div className='hidden md:block'>
          <a ref={ref}></a>
          <div className='flex justify-between w-full items-center'>
            <div className='flex gap-2 items-center'>
              <h1 className='text-[24px] font-bold'>{t('transactions.title')}</h1>
            </div>
          </div>
        </div>

        <div className='md:hidden'>
          <div className='flex justify-between items-center pt-safe'>
            {user && <TabletMenu user={user} />}
            <h2 className='text-[18px] font-bold'>{t('transactions.title')}</h2>
            <div className='w-[42px]'></div>
          </div>
        </div>

        <div className='w-full flex flex-col md:flex-row justify-center h-full'>
          <TransactionsSkeleton />
        </div>
      </main>
    );
  }

  return (
    <main
      className={cn('w-full py-[15px] px-[15px] bg-white md:mb-0 md:px-[30px] md:bg-light', {
        'mb-[100px]': isCompleted,
        'mb-[150px]': !isCompleted,
      })}
    >
      <div className='hidden md:block'>
        <a ref={ref}></a>
        <div className='flex justify-between w-full items-center'>
          <div className='flex gap-2 items-center'>
            <h1 className='text-[24px] font-bold'>{t('transactions.title')}</h1>
          </div>
        </div>
        <section className='bg flex justify-between gap-[20px] mt-[19px]'>
          <div className='grow w-[25%]'>
            <LiveSearch
              className='bg-white py-[9px] text-[14px] w-full'
              inputClassName='bg-white'
              results={results}
              value={selectedProfile?.name}
              renderItem={item => (
                <div className='flex gap-[15px] items-center'>
                  <div className='relative rounded-full w-4 h-4 object-cover'>
                    <Avatar className='w-4 h-4'>
                      {item.avatar ? (
                        <AvatarImage src={item.avatar} alt={item.name} className='object-cover' />
                      ) : null}
                      <AvatarFallback>
                        <FaUserCircle className='text-grey w-4 h-4' />
                      </AvatarFallback>
                    </Avatar>
                  </div>
                  <span>{item.name}</span>
                </div>
              )}
              onEnter={handleOnEnter}
              onChange={handleChange}
              onSelect={item => {
                setSelectedProfile(item);
                onFilterChange('clientId', !item ? '' : item.id);
              }}
            />
          </div>
          <div className='bg-white grow h-fit rounded-md w-[25%]'>
            <DropDown
              options={payStatuses}
              value={filters.status}
              filterMode
              onValueChange={v => onFilterChange('status', v)}
              name={'status'}
              placeholder='Payment status'
              className='bg-white rounded-md py-[9px] text-[14px]'
              contentClassName='text-[14px]'
            />
          </div>
          <div className='bg-white grow h-fit rounded-md w-[25%]'>
            <DropDown
              options={payments}
              value={filters.subscriptionOption}
              filterMode
              onValueChange={v => onFilterChange('subscriptionOption', v)}
              name={'options'}
              placeholder='Subscription options'
              className='bg-white rounded-md py-[9px] text-[14px]'
              contentClassName='text-[14px]'
            />
          </div>
          <div className='grow h-fit w-[25%]'>
            <CalendarPicker
              initialDateFrom={filters.date?.from}
              initialDateTo={filters.date?.to}
              onUpdate={v => {
                const { from, to } = v.range;

                onFilterChange('date', {
                  from: checkDate(from),
                  to: checkDate(to),
                });
              }}
            />
          </div>
        </section>
        <section className='rounded-md bg-white p-[30px] mt-[30px]'>
          {receivedPages?.pages[0].statistic ? (
            <TransactionStats
              amount={receivedPages?.pages[0].statistic.amount || 0}
              consultations={receivedPages?.pages[0].statistic.consultation || 0}
              pack={receivedPages?.pages[0].statistic.pack || 0}
              single={receivedPages?.pages[0].statistic.single || 0}
              payments={receivedPages?.pages[0].statistic.payments || 0}
            />
          ) : null}
          <Table className='mt-[30px] hidden md:table'>
            <TableHeader>
              <TableRow>
                <TableHead className='pl-[20px] py-[16px] tracking-widest'>
                  {t('transactions.table.payer')}
                </TableHead>
                <TableHead className='py-[16px] tracking-widest'>
                  {t('transactions.table.optionPrice')}
                </TableHead>
                <TableHead className='py-[16px] tracking-widest'>
                  <div className='flex gap-[8px] items-center'>
                    {t('transactions.table.amount')}
                  </div>
                </TableHead>
                <TableHead className='py-[16px] tracking-widest'>
                  {t('transactions.table.status')}
                </TableHead>
                <TableHead className='py-[16px] tracking-widest'>
                  {t('transactions.table.chat')}
                </TableHead>
                <TableHead className='pr-[20px] py-[16px] tracking-widest'>
                  {t('transactions.table.invoice')}
                </TableHead>
              </TableRow>
            </TableHeader>
            {!isFetching && receivedPages ? (
              <TableBody className='[&_tr:last-child]:border-b-[1px]'>
                {receivedPages?.pages?.map(({ data }) =>
                  data && Object.keys(data).length ? (
                    Object.keys(data).map(key => {
                      return (
                        <React.Fragment key={key}>
                          {renderedKeys.includes(key) ? null : (
                            <TableRow>
                              <TableCell
                                className='py-[10px] px-[20px] text-[11px] leading-[18px] uppercase text-grey-600 border-b-[1px] border-b-[#E5EAEE] w-full tracking-widest'
                                colSpan={6}
                              >
                                {key}
                              </TableCell>
                            </TableRow>
                          )}
                          {data[key].map(
                            (
                              {
                                avatar,
                                id,
                                chatId,
                                subscriptionType,
                                username,
                                price,
                                status,
                                chatStatus,
                                isCommon,
                              },
                              idx,
                            ) => {
                              renderedKeys = [...renderedKeys, key];

                              return (
                                <TableRow key={idx}>
                                  <TableCell className='pl-[20px]'>
                                    <div className='flex gap-[15px] items-center h-fit'>
                                      <div className='relative rounded-full w-[30px] h-[30px] object-cover'>
                                        <Avatar className='w-[30px] h-[30px]'>
                                          {avatar ? (
                                            <AvatarImage
                                              src={avatar}
                                              alt={username}
                                              className='object-cover'
                                            />
                                          ) : null}
                                          <AvatarFallback>
                                            <FaUserCircle className='text-grey w-[30px] h-[30px]' />
                                          </AvatarFallback>
                                        </Avatar>
                                      </div>
                                      <span className='font-semibold'>{username}</span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className='secondary flex items-center gap-[5px]'>
                                      {t(`onboarding.subscriptions.options.${subscriptionType}`)}
                                      {isCommon ? (
                                        <Tooltip
                                          text={
                                            'You have used the common payment credentials, so your funds will transfer to the Anamata account'
                                          }
                                        >
                                          <FaCreativeCommonsBy className='text-info' />
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className='font-semibold'>${price}</div>
                                  </TableCell>
                                  <TableCell>
                                    <div>
                                      <PayStatusCard variant={status} />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      onClick={() =>
                                        navigate(
                                          generateChatLink(
                                            `../${ROUTES.CHATS}/${chatId}`,
                                            chatStatus,
                                          ),
                                        )
                                      }
                                    >
                                      <Tooltip text={t('transactions.tooltip.chat')}>
                                        <div className='bg-light py-[8px] px-[14px] text-grey-500 hover:text-primary hover:bg-[#009EF7] hover:bg-opacity-10 rounded-md'>
                                          <FaComment />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {status === TransactionStatusEnum.PAID ? (
                                      <Tooltip text={t('transactions.tooltip.download')}>
                                        <div
                                          className='bg-light py-[8px] px-[14px] text-grey-500 hover:text-primary hover:bg-[#009EF7] hover:bg-opacity-10 rounded-md'
                                          onClick={() => getInvoice(id)}
                                        >
                                          <FaDownload />
                                        </div>
                                      </Tooltip>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} className='text-center text-[14px] text-grey-500'>
                        {t('transactions.notFound')}
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            ) : (
              <TableSkeletonBody />
            )}
          </Table>
          {receivedPages && Object.keys(receivedPages?.pages[0].data).length ? (
            <>
              <div className='flex justify-between items-center mt-[13px]'>
                {hasNextPage ? (
                  <Button className='text-[14px] text-primary' onClick={() => fetchNextPage()}>
                    {t('transactions.pagination.button')}
                  </Button>
                ) : (
                  <div></div>
                )}
                <Button onClick={() => getQuickbookSheet(filters)} className='text-primary'>
                  <FaDownload className='text-primary hover:opacity-50' />
                  {t('transactions.csv.desktop')}
                </Button>
              </div>
            </>
          ) : null}
        </section>
      </div>
      <div className='md:hidden'>
        <div className='flex justify-between items-center pt-safe'>
          {user && <TabletMenu user={user} />}
          <h2 className='text-[18px] font-bold'>{t('transactions.title')}</h2>
          <Button onClick={() => setIsSettingsEnabled(!isSettingsEnabled)}>
            <FaSlidersH
              className={cn('w-[22px] h-[22px] text-grey', {
                'text-primary': isSettingsEnabled,
              })}
            />
          </Button>
        </div>
        {!isSettingsEnabled ? (
          receivedPages?.pages[0].statistic ? (
            <TransactionStats
              className='mt-[15px]'
              amount={receivedPages?.pages[0].statistic.amount || 0}
              consultations={receivedPages?.pages[0].statistic.consultation || 0}
              pack={receivedPages?.pages[0].statistic.pack || 0}
              single={receivedPages?.pages[0].statistic.single || 0}
              payments={receivedPages?.pages[0].statistic.payments || 0}
            />
          ) : null
        ) : (
          <div className='flex flex-col gap-[15px] mt-[15px]'>
            <div>
              <LiveSearch
                className='bg-light py-[9px] text-[14px] w-full'
                inputClassName='bg-light'
                results={results}
                value={selectedProfile?.name}
                renderItem={item => (
                  <div className='flex gap-[15px] items-center'>
                    <div>
                      <FaUserCircle className='text-grey w-4 h-4' />
                    </div>
                    <span>{item.name}</span>
                  </div>
                )}
                onEnter={handleOnEnter}
                onChange={handleChange}
                onSelect={item => {
                  setSelectedProfile(item);
                  onFilterChange('clientId', !item ? '' : item.id);
                }}
              />
            </div>
            <div className='bg-light h-fit rounded-md'>
              <DropDown
                options={payStatuses}
                value={filters.status}
                onValueChange={v => onFilterChange('status', v)}
                name={'status'}
                placeholder='Payment status'
                className='bg-light rounded-md py-[9px] text-[14px]'
                contentClassName='text-[14px]'
                filterMode
              />
            </div>
            <div className='bg-white h-fit rounded-md'>
              <DropDown
                options={payments}
                value={filters.subscriptionOption}
                onValueChange={v => onFilterChange('subscriptionOption', v)}
                name={'options'}
                placeholder='Subscription options'
                className='bg-light rounded-md py-[9px] text-[14px]'
                contentClassName='text-[14px]'
                filterMode
              />
            </div>
            <div className='h-fit'>
              <CalendarPicker
                initialDateFrom={filters.date?.from}
                initialDateTo={filters.date?.to}
                onUpdate={v => {
                  const { from, to } = v.range;

                  onFilterChange('date', {
                    from: checkDate(from),
                    to: checkDate(to),
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className='flex items-center justify-between mt-[30px]'>
          <h2 className='text-[18px] font-bold'>{t('transactions.mobileHeader')}</h2>
          {receivedPages && Object.keys(receivedPages?.pages[0].data).length ? (
            <Button onClick={() => getQuickbookSheet(filters)} className='text-primary'>
              <FaDownload className='text-primary hover:opacity-50' />
              {t('transactions.csv.mobile')}
            </Button>
          ) : null}
        </div>
        {!isFetching && receivedPages ? (
          <div className='mt-[15px] border-t-[1px] border-t-[#E5EAEE] overflow-y-scroll'>
            {receivedPages?.pages?.map(({ data }) =>
              data && Object.keys(data).length ? (
                Object.keys(data).map(key => {
                  renderedKeys = [...renderedKeys, key];
                  return (
                    <React.Fragment key={key}>
                      {renderedKeys.includes(key) ? (
                        <div className='py-[15px] text-center text-[11px] leading-[18px] uppercase text-grey-600 border-b-[1px] border-b-[#E5EAEE] w-full'>
                          {key}
                        </div>
                      ) : null}
                      {data[key].map((transactionData, idx) => (
                        <TransactionCard key={idx} {...transactionData} getInvoice={getInvoice} />
                      ))}
                    </React.Fragment>
                  );
                })
              ) : (
                <div className='mt-[20px] text-center text-[14px] text-grey-500'>
                  {t('transactions.notFound')}
                </div>
              ),
            )}
            {hasNextPage ? (
              <Button
                className='text-[14px] text-primary mt-[13px]'
                onClick={() => fetchNextPage()}
              >
                {t('transactions.pagination.button')}
              </Button>
            ) : null}
          </div>
        ) : (
          <MobileSkeletonBody />
        )}
      </div>
    </main>
  );
};
