import React from 'react';
import { cn } from 'src/common/utils';
import { TabletMenu } from 'src/components/TabletMenu';
import { UserMenu } from 'src/components/UserMenu';
import { UserInterface } from 'src/store/user/user.slice';
import { SharedMenuGroup } from 'src/ui/MenuBar/MenuBar';
import { MobileBackButton } from 'src/ui/MobileBackButton/MobileBackButton';

export interface PageWrapperProps {
  isChanged?: boolean;
  headerText: string;
  activeElemets?: React.ReactNode;
  mobileActiveElements?: React.ReactNode;
  children?: React.ReactNode;
  isMainPage?: boolean;
  user?: null | UserInterface;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  activeElemets,
  children,
  headerText,
  isChanged,
  mobileActiveElements,
  isMainPage,
  user,
}) => {
  return (
    <main className='w-full px-[15px] md:px-[30px] pb-[15px] bg-light relative'>
      <div
        className={cn(
          'flex justify-between w-[calc(100%+30px)] -ml-[15px] items-center top-0 left-0 bg-white p-[15px] pt-[20px] pb-[23px] md:pt-[15px] md:pb-[15px] sticky z-50 md:bg-light md:ml-0 md:w-full md:px-0',
          {
            'md:sticky': isChanged,
            'md:relative': !isChanged,
            'bg-transparent p-[15px] relative': isMainPage,
          },
        )}
      >
        {!isMainPage ? (
          <>
            <div className='flex gap-[15px] items-center'>
              <MobileBackButton className='md:block lg:hidden' />
              <h1 className='text-[16px] md:text-[24px] font-bold'>{headerText}</h1>
            </div>
            <div className='gap-4 hidden md:flex'>{activeElemets}</div>
          </>
        ) : (
          <>
            <div className='flex justify-between items-center w-full'>
              <div className='lg:hidden'>{user ? <TabletMenu user={user} /> : null}</div>
              <h1 className='text-[18px] md:text-[24px] font-bold'>{headerText}</h1>
              <div className='w-[42px] md:w-[60px]'></div>
            </div>
          </>
        )}
      </div>
      {children}
      {mobileActiveElements ? (
        <div
          className={cn(
            'px-[15px] md:hidden pb-[36px] block bottom-0 bg-green -ml-[15px] bg-light -mb-[15px] w-[calc(100%+30px)]',
            {
              'sticky bg-white z-50': isChanged,
              'pt-[30px]': !isChanged,
            },
          )}
        >
          {isChanged ? (
            <div
              className='absolute w-full h-[25px] bg-swhite -mx-[15px] -top-[25px]'
              style={{
                background:
                  'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
              }}
            ></div>
          ) : null}
          {mobileActiveElements}
        </div>
      ) : null}
    </main>
  );
};
