import { fetchBuilder } from 'src/services/basicFetch';
import { MethodEnum, StorageKeysEnum } from '../enums';

export const downloadFileBuffer = async (fileName: string) => {
  return await fetchBuilder({}, `storage/download/${fileName}`, MethodEnum.GET);
};

export const updateUserOffer = async (id: number, offer: File, isTemplate?: boolean) => {
  return await fetchBuilder(
    {},
    `user/${id}/offer${isTemplate ? '?isTemplate=true' : ''}`,
    MethodEnum.PATCH,
    [offer] as File[],
  );
};

export const deleteImages = async (
  imagesToDelete: {
    key: string;
    url: string;
  }[],
  id: number,
) => {
  return await fetchBuilder({ imagesToDelete }, `user/${id}/media`, MethodEnum.DELETE);
};

export const uploadFiles = async (
  toUpload: {
    file: File;
    key: string;
  }[],
  link: string,
  mode: RequestMode = 'cors',
  method: MethodEnum = MethodEnum.PATCH,
): Promise<{ key: string; url: string }[] | undefined> => {
  const formData = new FormData();
  for (const file of toUpload) {
    formData.append(file.key, file.file);
  }
  const access_token = localStorage.getItem(StorageKeysEnum.TOKEN);
  const apiUrl =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_URL : '/api';

  const response = await fetch(`${apiUrl}/${link}`, {
    method,
    headers: {
      ...(access_token && { Authorization: `Bearer ${access_token}` }),
    },
    mode,
    body: formData,
  });

  try {
    return await response.json();
  } catch (err) {
    console.log(err, response.url);
  }
};
