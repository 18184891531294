import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Tooltip } from '../Tooltip/Tooltip';
import { cn } from 'src/common/utils';

export interface TypedTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  measure: string;
  width?: string;
  disabled?: boolean;
}

export const TypedTextInput: React.FC<TypedTextInputProps> = ({
  measure,
  placeholder,
  width = 'w-full',
  disabled = false,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const regex = /^(?!0)\$?\d{1,6}(\.\d{1,2})?$/;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (regex.test(newValue) || newValue === '') {
      setInputValue(newValue);
      onChange?.(e);
      setError(null);
    } else {
      setError(t('common.currencyInputError'));
    }
  };

  return (
    <div>
      <div
        className={cn(
          `flex secondary h-[50px]
           focus-within:shadow-[0_0_4px_0_#009EF7_inset]
           focus-within:bg-primary-light
           rounded-md bg-light justify-between ${width}`,
          {
            'opacity-80': disabled,
            'bg-danger-light': !!error,
          },
        )}
      >
        <input
          value={inputValue}
          onChange={handleChange}
          onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          disabled={disabled}
          type='number'
          placeholder={placeholder}
          pattern='\d*'
          className={`grow
            min-w-0
            md:max-w-[calc(100%-30px)]
            rounded-l-md
            bg-transparent
            py-[14px] pl-[15px]
            [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
            focus:outline-none focused:bg-light
            hover:shadow-[0_0_4px_0_rgba(63, 66, 84, 0.30)_inset]`}
        />
        {error ? (
          <div className='tooltip my-auto mb-[10px]'>
            <Tooltip text={error} className='normal-case font-normal'>
              <FaExclamationTriangle className='w-[18px] h-[18px] text-danger' />
            </Tooltip>
          </div>
        ) : null}
        <div className='py-[15px] ml-[10px] mr-[15px]'>{measure}</div>
      </div>
    </div>
  );
};
