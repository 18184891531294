import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/ui/Button/Button';
import { FaDownload } from 'react-icons/fa';
import { QRCodeCanvas } from 'qrcode.react';
import { useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { cn } from 'src/common/utils';

interface QRCodeProps {
  baseLink: string;
  isPage?: boolean;
}

const QRcode: React.FC<QRCodeProps> = ({ baseLink, isPage }) => {
  const { t } = useTranslation();

  const downloadRef = useRef<HTMLAnchorElement | null>(null);
  const { user } = useSelector((state: AppStateType) => state.user);

  const download = () => {
    const canvas = document.querySelector('#personalQR_big > canvas') as HTMLCanvasElement;

    if (canvas && downloadRef.current) {
      downloadRef.current.href = canvas.toDataURL();
      downloadRef.current.download = 'link-QR.png';
    }
  };

  return user?.expert?.domainName ? (
    <div
      className={cn(
        `md:rounded-md bg-light 
        h-[278px] flex flex-col
        items-center justify-center
        -mx-[15px] px-[15px] py-[20px] md:mx-0
        `,
        {
          '-mx-[30px]': isPage,
        },
      )}
    >
      <div id='personalQR' className='bg-white rounded-[15px] p-[16px]'>
        <QRCodeCanvas value={`${user?.expert?.domainName}.${baseLink}`} />
      </div>
      <div id='personalQR_big' className='hidden'>
        <QRCodeCanvas value={`${user?.expert?.domainName}.${baseLink}`} size={256} />
      </div>
      <a ref={downloadRef} onClick={() => download()}>
        <Button variant='secondary' className='bg-transparent'>
          <FaDownload className='max-w-fit bg-light' />
          {t('common.files.download')}
        </Button>
      </a>
    </div>
  ) : null;
};

export { QRcode };
