import React from "react";
import { cn } from "src/common/utils";
import { Card } from "src/ui/Card/Card"

export interface TransactionStatsProps {
    title: string;
    amount: number | string;
    className?: string;
    currency?: string;
}

export const TransactionStat: React.FC<TransactionStatsProps> = ({
    amount,
    title,
    className,
    currency
}) => {
    return (
      <Card variant='dashed' className={cn('w-full border-[#D8D8E5]', className)}>
        <div className='text-grey-500 uppercase text-[11px] tracking-widest'>{title}</div>
        <div className='text-[16px]'>
          {currency}
          {amount}
        </div>
      </Card>
    );
}

export interface TransactionsStatsProps {
    amount: number;
    payments: number;
    consultations: number;
    pack: number;
    single: number;
    className?: string;
}

export const TransactionStats: React.FC<TransactionsStatsProps> = ({
    amount,
    payments,
    consultations,
    pack,
    single,
    className
}) => {
    return (
        <div className={cn('grid md:flex gap-[15px]', className)}>
            <TransactionStat title="Amount" amount={(+amount).toFixed(2)} currency="$" className='col-span-2' />
            <TransactionStat title="Payments" amount={payments} />
            <TransactionStat title="Consultation" amount={consultations} />
            <TransactionStat title="Questions pack" amount={pack} />
            <TransactionStat title="One question" amount={single} />
        </div>
    )
}