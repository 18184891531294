import React, { useContext, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectUser } from 'src/store/user/user.slice';
import { SocketContext } from 'src/context/socket.context';
import { useMutation } from '@tanstack/react-query';

import { SendPaymentButton } from '../PaymentButton';

import { useChatStore } from 'src/store/chat/chatStore';
import { MessageVariant } from 'src/common/interfaces';
import { ChatStatus } from 'src/common/interfaces';
import ChatClientRequest from 'src/ui/ChatClientRequest/ChatClientRequest';
import { ConnectAiButton, SuggestAiButton } from '../ConnectAiButton';
import { useActiveChat } from 'src/store/chat/useActiveChat';
import { MessagesWithDateIndicator } from '../Messages';
import MessageInput from 'src/ui/MessageInput/MessageInput.chat';
import { sendMessage } from 'src/common/api/chat';
import { ClientMessage } from './ClientMessage';
import { ChatDateIndicator } from '../common/ChatDateIndicator';
import {
  connectAiHelperToChat,
  suggestAiHelperMessage,
  summariseViaAi,
} from 'src/common/api/ai-chat';
import { useToast } from 'src/ui/Toast/use-toast';
import { useTranslation } from 'react-i18next';
import { ChatPaymentButton } from 'src/ui/ChatPaymentButton/ChatPaymentButton';
import { ROUTES } from 'src/router/routes';
import { getFromChatStorage, saveChatToStorage } from 'src/common/utils';
import { WritingPlaceholder } from 'src/ui/WritingPlaceholder/WritingPlaceholder';

const ClientChat = () => {
  const { chatId } = useParams();
  const { toast } = useToast();
  const {
    i18n: { language },
  } = useTranslation();

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState<boolean>(false);

  const { activeChatInfo, addNewMessage } = useChatStore();

  const { refetch, isLoading } = useActiveChat(chatId);

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(selectUser);

  const socket = useContext(SocketContext);

  const { mutate: sendChatMessage } = useMutation({
    mutationFn: sendMessage,
    onSuccess: message => {
      addNewMessage(message);
    },
  });

  const { mutate: connectAiHelper, isLoading: isConnecting } = useMutation({
    mutationFn: (chatId: string) => connectAiHelperToChat({ chatId, language }),
    onSuccess: data => {
      toast({
        variant: 'success',
        title: 'Successfully connected AI helper to the chat',
      });
      refetch();
    },
  });

  const { mutate: summarize, isLoading: isSummarizing } = useMutation({
    mutationFn: ({ chatId, clientId }: { chatId: string; clientId: string }) =>
      summariseViaAi({
        chatId,
        language,
        clientId,
      }),
    onSuccess: () => {
      refetch().then(() => {
        setTimeout(() => navigate({ pathname: ROUTES.AI_CHAT, search: location.search }));
      });
    },
  });

  const { mutate: suggestAnswer, isLoading: isGenerating } = useMutation({
    mutationFn: ({ chatId, clientId }: { chatId: string; clientId: string }) =>
      suggestAiHelperMessage({
        chatId,
        clientId,
        language,
      }),
    onSuccess: () => {
      refetch().then(() => {
        setTimeout(() => navigate({ pathname: ROUTES.AI_CHAT, search: location.search }));
      });
    },
  });

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user?.id && chatId) {
      socket.emit('join-chat', { userId: user.id, chatId });
      const text = getFromChatStorage(chatId);
      if (!text) {
        saveChatToStorage(chatId);
      } else {
        setMessage(text);
        sessionStorage.clear();
      }
    }

    return () => {
      if (user?.id && chatId) socket.emit('left-chat', { userId: user.id, chatId });
    };
  }, [chatId, user?.id]);

  if (!chatId || !activeChatInfo) return <></>;

  if (!isLoading && !activeChatInfo) return <Navigate to='/' />;

  if (!user) {
    return <Navigate to='/' />;
  }

  useEffect(() => {
    if (activeChatInfo.status === ChatStatus.CLOSED) {
      setMessage('');
    }
  }, [activeChatInfo.status]);

  const isClosed = activeChatInfo.status === ChatStatus.CLOSED;

  const isNotPaid = activeChatInfo.status === ChatStatus.IDLE;

  const isRequested = activeChatInfo.status === ChatStatus.REQUESTED;

  const hasAiChat = !!activeChatInfo.aiChatId;

  const useAiHelper = user.expert?.settings?.useAiHelper;

  const showAiConnectButton = !isClosed && !isRequested && !hasAiChat && useAiHelper;
  const showSuggestButton = !isClosed && !isRequested && hasAiChat && activeChatInfo.aiChatId;

  return (
    <>
      <div className='w-full flex flex-col-reverse h-full px-[15px] sm:px-[30px] gap-3 overflow-y-scroll overflow-x-clip scrollbar-hide pt-[75px] sm:pt-0'>
        {isNotPaid && (
          <div className='flex justify-center w-full'>
            <SendPaymentButton
              ButtonComp={ChatPaymentButton}
              className='w-full text-primary bg-primary-light max-w-[800px] mt-6'
            />
          </div>
        )}
        {showAiConnectButton && (
          <ConnectAiButton
            chatId={activeChatInfo?.id}
            connect={connectAiHelper}
            isLoading={isConnecting}
          />
        )}
        {isAttachmentLoading && (
          <WritingPlaceholder
            className='max-w-[80px] bg-white -scale-x-100 scale-y-100 ml-auto bg-primary'
            textClassName='!bg-white'
          />
        )}
        {!isInputFocused && showSuggestButton && (
          <div className='flex items-center gap-[15px] justify-center'>
            <SuggestAiButton
              chatId={activeChatInfo.id}
              isLoading={isGenerating}
              disabled={isSummarizing || isGenerating}
              text={'chats.ai.suggest'}
              connect={() => suggestAnswer({ chatId, clientId: activeChatInfo.client.id })}
            />
            <SuggestAiButton
              chatId={activeChatInfo.id}
              isLoading={isSummarizing}
              disabled={isSummarizing || isGenerating}
              text={'chats.ai.summarize'}
              connect={() => summarize({ chatId, clientId: activeChatInfo.client.id })}
            />
          </div>
        )}
        {!isRequested && (
          <MessagesWithDateIndicator
            messages={activeChatInfo.messages}
            chatCreatedAt={activeChatInfo.createdAt}
            MessageComponent={ClientMessage}
            props={{ messengerType: activeChatInfo.messengerType }}
          />
        )}
        <ChatClientRequest
          chatId={chatId}
          username={activeChatInfo.client?.name}
          userImage={activeChatInfo.client?.avatar}
          messengerType={activeChatInfo.messengerType}
          subscriptionType={activeChatInfo.subscriptionType}
          message={activeChatInfo.messages.find(
            m => m.variant === MessageVariant.CLIENT_DESCRIPTION,
          )}
          isRequested={isRequested}
        />
        <ChatDateIndicator date={activeChatInfo.createdAt} className='mt-4' />
      </div>
      <div className='w-full pt-4 sm:px-[30px] sm:pb-4'>
        <div
          className={
            'secondary w-full flex flex-col py-[20px] pb-[45px] px-[15px] sm:pb-[20px] rounded-md bg-white focus-within:shadow-[0_0_4px_0_#009EF7_inset]  hover:shadow-[0_0_4px_#3F42544D_inset]'
          }
        >
          <MessageInput
            setIsAttachmentLoading={setIsAttachmentLoading}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => {
              setIsInputFocused(false);
              saveChatToStorage(chatId, message);
            }}
            value={message}
            onChange={setMessage}
            onMessageSend={message => {
              sendChatMessage({ chatId, body: message });
              setMessage('');
            }}
            connectButton={
              showAiConnectButton && (
                <ConnectAiButton
                  variant='small'
                  chatId={activeChatInfo?.id}
                  connect={connectAiHelper}
                  isLoading={isConnecting}
                />
              )
            }
            disabled={isClosed || isRequested}
          />
        </div>
      </div>
    </>
  );
};

export { ClientChat };
