import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { fetchBuilder } from '../../services/basicFetch';
import { MethodEnum } from 'src/common/enums';
import { ExpertSubscription, PaymentSettingsInterface, UserInterface } from './user.slice';

export const authUser = createAsyncThunk('AUTH', async (userData: any) => {
  try {
    const data = await fetchBuilder(userData, 'auth', MethodEnum.POST);
    return data;
  } catch (error) {
    console.error(error);
  }
});

export const verifyOTP = createAsyncThunk(
  'VERIFY_OTP',
  async (
    userData: {
      email: string;
      otp: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const data = await fetchBuilder(userData, 'verify-otp', MethodEnum.POST);
      return data;
    } catch (error: any) {
      if (error.message === 'Unauthorized') {
        return rejectWithValue({ unauthorized: true });
      }
      throw error;
    }
  },
);

export const getUserViaToken = createAsyncThunk('GET', async ({ token }: { token: string }) => {
  try {
    const data = await fetchBuilder(
      {
        token,
      },
      `user/current`,
      MethodEnum.GET,
    );
    return {
      access_token: token,
      user: data,
    };
  } catch (error) {
    console.error(error);
  }
});

export const updateUser = createAsyncThunk(
  'UPDATE',
  async ({ userData }: { userData: Partial<UserInterface> }) => {
    try {
      const data = await fetchBuilder(
        {
          ...userData,
        },
        `user/${userData.id}`,
        MethodEnum.PATCH,
      );
      return {
        user: data,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateUserProfile = createAsyncThunk(
  'UPDATE_PROFILE',
  async ({
    token,
    userAvatar,
    userData,
  }: {
    token: string;
    userAvatar?: File | 'DELETED' | null;
    userData: Partial<UserInterface>;
  }) => {
    try {
      if (userAvatar === 'DELETED') {
        await fetchBuilder(
          {
            token,
          },
          `user/${userData.id}/avatar`,
          MethodEnum.DELETE,
        );
      } else if (userAvatar) {
        await fetchBuilder(
          {
            token,
          },
          `user/${userData.id}/avatar`,
          MethodEnum.PATCH,
          [userAvatar],
        );
      }

      const data = await fetchBuilder(
        {
          token,
          ...userData,
        },
        `user/${userData.id}/profile`,
        MethodEnum.PATCH,
      );
      return {
        access_token: token,
        user: data,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateLogos = createAsyncThunk(
  'UPDATE_LOGOS',
  async ({
    token,
    id,
    userAvatar,
    brandLogo,
  }: {
    token: string;
    id: number;
    userAvatar?: File | null | 'DELETED';
    brandLogo?: File | null | 'DELETED';
  }) => {
    try {
      let receivedUser, receivedExpert;
      if (userAvatar === 'DELETED') {
        receivedUser = await fetchBuilder(
          {
            token,
          },
          `user/${id}/avatar`,
          MethodEnum.DELETE,
        );
      } else if (userAvatar) {
        receivedUser = await fetchBuilder(
          {
            token,
          },
          `user/${id}/avatar`,
          MethodEnum.PATCH,
          [userAvatar],
        );
      }

      if (brandLogo === 'DELETED') {
        receivedExpert = await fetchBuilder(
          {
            token,
          },
          `expert/${id}/logo`,
          MethodEnum.DELETE,
        );
      } else if (brandLogo) {
        receivedExpert = await fetchBuilder(
          {
            token,
          },
          `expert/${id}/logo`,
          MethodEnum.PATCH,
          [brandLogo],
        );
      }

      return {
        access_token: token,
        avatarUrl: receivedUser?.avatarUrl,
        brandLogoUrl: receivedExpert?.brandLogoUrl,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateBusinessCard = createAsyncThunk(
  'UPDATE_BUSINESS_CARD',
  async ({
    token,
    id,
    brandLogo,
    userData,
  }: {
    token: string;
    id: number;
    brandLogo?: File | null | 'DELETED';
    userData: Partial<UserInterface>;
  }) => {
    try {
      let expert;
      if (brandLogo === 'DELETED') {
        await fetchBuilder(
          {
            token,
          },
          `expert/${id}/logo`,
          MethodEnum.DELETE,
        );
      } else if (brandLogo) {
        expert = await fetchBuilder(
          {
            token,
          },
          `expert/${id}/logo`,
          MethodEnum.PATCH,
          [brandLogo],
        );
      }

      const receivedUser = await fetchBuilder(
        {
          token,
          ...userData,
        },
        `user/${id}/profile`,
        MethodEnum.PATCH,
      );

      return {
        access_token: token,
        user: {
          ...receivedUser,
          expert: {
            ...receivedUser.expert,
            brandLogoUrl: expert ? expert.brandLogoUrl : receivedUser.expert.brandLogoUrl,
          },
        },
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateViaAssistant = createAsyncThunk(
  'UPDATE_VIA_ASSISTANT',
  async (data: { user: UserInterface; message: string; role: string; step: number }) => {
    try {
      const user = await fetchBuilder(data, 'assistant/check-status', MethodEnum.POST);
      return {
        ...user,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateAvatars = createAsyncThunk(
  'UPDATE_LOGOS_VIA_ASSISTANT',
  async ({ userAvatar, brandLogo }: { userAvatar?: File | null; brandLogo?: File | null }) => {
    try {
      const answer = await fetchBuilder({}, 'assistant/avatars', MethodEnum.PATCH, [
        userAvatar,
        brandLogo,
      ] as File[]);
      return {
        messages: answer,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateOffer = createAsyncThunk(
  'UPDATE_OFFER',
  async ({ id, offer }: { id: number, offer?: File | null }) => {
    try {
      const user = await fetchBuilder({}, `user/${id}/offer`, MethodEnum.PATCH, [
        offer,
      ] as File[]);
      return user;
    } catch (e) {
      console.error(e);
    }
  })

export const getSubscriptions = createAsyncThunk(
  'GET_EXPERT_SUBSCRIPTIONS',
  async ({ id }: { id: number }) => {
    try {
      const response = await fetchBuilder({}, `expert-subscription/${id}`, MethodEnum.GET);
      return {
        subscriptions: response,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updateSubscriptions = createAsyncThunk(
  'UPDATE_EXPERT_SUBSCRIPTIONS',
  async ({ id, subscriptions }: { id: number, subscriptions: ExpertSubscription[] }) => {
    try {
      const response = await fetchBuilder({ subscriptions }, `expert-subscription/user/${id}`, MethodEnum.PATCH);
      return {
        subscriptions: response,
      };
    } catch (error) {
      console.error(error);
    }
  },
);

export const updatePaymentSettings = createAsyncThunk(
  'UPDATE_PAY_SETTINGS',
  async (paySettings: PaymentSettingsInterface) => {
    try {
      const response = await fetchBuilder({ ...paySettings }, `user/payment`, MethodEnum.PATCH);
      return {
        user: response,
      };
    } catch (error) {
      console.error(error);
    }
  }
)
