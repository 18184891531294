import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabSwitcher from 'src/ui/TabSwitch/TabSwitch';
import { TextBuffer } from 'src/ui/TextBuffer/TextBuffer';
import { cn } from 'src/common/utils/cn';
import { QRcode } from './QRCode';
import { ROUTES } from 'src/router/routes';

type MyLinksTabProps = {
  domainName?: string;
  className?: string;
  isPage?: boolean;
};

const MyLinksTab = ({ domainName, className }: MyLinksTabProps) => {
  const { t } = useTranslation();
  const baseLink = useMemo(() => {
    return window.location.hostname.split('.').slice(1).join('.') || 'localhost:3000';
  }, [window]);
  const [error, setError] = useState<Error | null>(null);
  const [tab, setTab] = useState<string | number>(0);

  return (
    <TabSwitcher
      className={cn(className, 'w-full rounded-md')}
      value={tab}
      onChange={setTab}
      tabs={[
        {
          label: t('onboarding.finish.tabs.link'),
          content: domainName && (
            <TextBuffer isPage domainName={domainName} baseLink={baseLink} setError={setError} />
          ),
          value: 0,
          className: 'text-[14px]',
        },
        {
          label: t('onboarding.finish.tabs.code'),
          content: <QRcode isPage baseLink={baseLink} />,
          disabled: !!error,
          value: 1,
          className: 'text-[14px]',
        },
      ]}
    />
  );
};

export { MyLinksTab };
