import React, { useMemo, useState } from 'react';
import { Button } from 'src/ui/Button/Button';
import { Card } from 'src/ui/Card/Card';
import LineChart from 'src/ui/LineChart/LineChart';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/ui/Table/Table';
import { ROUTES } from 'src/router/routes';
import { FaArrowRight } from 'react-icons/fa';
import { DropDown } from 'src/ui/DropDownRadix/DropDown';
import { useQuery } from '@tanstack/react-query';
import { getChatsCreationStatistic } from 'src/common/api/chat';
import { AnalyticsStats, getAnalyticsStats } from 'src/common/api/analytics';
import { datePresets } from 'src/common/data';
import { v4 as uuidv4 } from 'uuid';
import { Skeleton } from 'src/ui/Skeleton/Skeleton';
import { AppStateType } from 'src/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const BusinessCard = () => {
  const navigate = useNavigate();
  const [chatStats, setChatStats] = useState<{
    total: number;
    data: number[];
    labels: string[];
  } | null>(null);
  const [analyticStats, setAnalyticStats] = useState<AnalyticsStats | null>(null);
  const [dateRange, setDateRange] = useState<string | null>(null);
  const { user } = useSelector((state: AppStateType) => state.user);
  const id = uuidv4();
  const { t } = useTranslation();

  const domainName = useMemo(() => {
    if (window && user?.expert) {
      const baseLink = window.location.hostname.split('.').slice(1).join('.') || 'localhost:3000';
      return `${user.expert.domainName}.${baseLink}`;
    }

    return null;
  }, [user?.expert?.domainName, window]);

  const { isLoading } = useQuery([dateRange, domainName], {
    queryFn: async () =>
      await Promise.all([
        getChatsCreationStatistic(dateRange),
        getAnalyticsStats(domainName, dateRange),
      ]),
    onSuccess: data => {
      const [chatData, analyticsData] = data;
      const total = chatData.reduce(
        (prev, curr) => {
          return {
            total: prev.total + curr.count,
            data: [...prev.data, curr.count],
            labels: [...prev.labels, curr.date],
          };
        },
        {
          total: 0,
          data: [] as number[],
          labels: [] as string[],
        },
      );

      setChatStats(total);
      setAnalyticStats(analyticsData);
    },
  });

  return !isLoading ? (
    <Card variant='default' className='pt-[22px] pb-[17px]'>
      <div className='flex justify-beween items-baseline w-full -mt-[5px]'>
        <h3 className='text-[18px] font-[700] p-0 m-0 grow'>{t('dashboard.business.title')}</h3>
        <div className='w-[150px]'>
          <DropDown
            key={id}
            placeholder='Date range'
            className='bg-transparent !pr-0 justify-end gap-[5px]'
            contentClassName='text-[14px]'
            name={`${id}`}
            options={datePresets}
            value={dateRange || 'last30'}
            onValueChange={v => setDateRange(v)}
            resetClassName='top-[10px] right-8'
          />
        </div>
      </div>
      <div className='flex mt-[18px] items-start gap-[30px] lg:flex-row flex-col'>
        <div className='flex gap-[15px] lg:w-fit w-full'>
          <div className='grow w-6/12 min-h-[155px]'>
            {analyticStats?.visits ? (
              <LineChart
                title='card visits'
                summary={
                  analyticStats.visits.data.length
                    ? analyticStats.visits.data.reduce((sum, d) => sum + d)
                    : 0
                }
                data={{
                  labels: analyticStats.visits.labels,
                  datasets: [
                    {
                      data: analyticStats.visits.data,
                      fill: true,
                      borderColor: '#009EF7',
                      borderWidth: 2,
                      pointRadius: 0,
                      backgroundColor: '#E5F5FE',
                      tension: 0.1,
                    },
                  ],
                }}
              />
            ) : null}
          </div>
          {chatStats ? (
            <div className='grow w-6/12 min-h-[155px]'>
              <LineChart
                title='chat started'
                summary={chatStats.total}
                data={{
                  labels: chatStats.labels,
                  datasets: [
                    {
                      data: chatStats.data,
                      fill: true,
                      borderColor: '#009EF7',
                      pointRadius: 0,
                      borderWidth: 2,
                      backgroundColor: '#E5F5FE',
                      tension: 0.1,
                    },
                  ],
                }}
              />
            </div>
          ) : null}
        </div>
        <div className='grow min-h-[155px] lg:w-fit w-full grow'>
          <div>
            <div className='flex w-full pb-[10px] border-b-[1px] border-b-[#E5EAEE]'>
              <div className='grow text-grey-500 text-[11px] leading-[18px] w-full tracking-widest uppercase'>
                {t('dashboard.business.referals')}
              </div>
              <div className='shrink text-[11px] leading-[18px] text-grey-900 min-w-[50px]'></div>
              <div className='shrink text-[11px] text-center text-grey-500 leading-[18px] tracking-widest uppercase min-w-[50px]'>
                {t('dashboard.business.views')}
              </div>
            </div>
            <div className='w-full'>
              {analyticStats && analyticStats.stats.length ? (
                analyticStats.stats.map(({ amount, percents, url }, idx) => (
                  <div className='flex w-full' key={idx}>
                    <div className='w-full grow py-[10px] col-span-10 truncate text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE]'>
                      {url}
                    </div>
                    <div className='shrink py-[10px] text-[11px] leading-[18px] text-center text-grey-900 border-b-[1px] border-b-[#E5EAEE] min-w-[50px]'>
                      {amount}
                    </div>
                    <div className='shrink py-[10px] text-[11px] leading-[18px] text-center text-grey-600 border-b-[1px] border-b-[#E5EAEE] min-w-[50px]'>
                      {percents}%
                    </div>
                  </div>
                ))
              ) : (
                <div className='py-[10px] text-[11px] leading-[18px] text-grey-900 w-full text-center'>
                  {t('dashboard.business.noReferals')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='mt-[16px] mx-auto w-fit text-primary text-[14px]'>
        <Button onClick={() => navigate(`../${ROUTES.LINKS}`)}>
          {t('dashboard.business.buttonText')}
          <FaArrowRight />
        </Button>
      </div>
    </Card>
  ) : (
    <Card variant='default' className='h-[300px]'>
      <div className='flex justify-beween items-baseline w-full'>
        <h3 className='text-[18px] font-[700] p-0 m-0 grow'>{t('dashboard.business.title')}</h3>
      </div>
      <div className='flex mt-[26px] items-start gap-[30px] lg:flex-row flex-col'>
        <div className='flex gap-[15px] lg:w-fit w-full grow'>
          <div className='grow w-6/12 min-h-[155px]'>
            <Skeleton className='w-full min-h-[180px]' />
          </div>
          <div className='grow w-6/12 min-h-[155px]'>
            <Skeleton className='w-full h-full  min-h-[180px]' />
          </div>
        </div>
        <div className='grow min-h-[155px] lg:w-fit w-full'>
          <Table>
            <TableHeader className='bg-transparent'>
              <TableRow>
                <TableHead
                  className='pb-[10px] pt-0 h-fit border-b-[1px] border-b-[#E5EAEE]'
                  colSpan={3}
                >
                  <Skeleton className='w-full h-[20px]' />
                </TableHead>
                <TableHead className='pb-[10px] pt-0 h-fit border-b-[1px] border-b-[#E5EAEE]'></TableHead>
                <TableHead className='pb-[10px] pt-0 h-fit border-b-[1px] border-b-[#E5EAEE]'>
                  <Skeleton className='w-full h-[20px]' />
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell
                  className='py-[10px] text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE] w-full'
                  colSpan={3}
                >
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
                <TableCell className='py-[10px] text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE] w-full'>
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
                <TableCell className='py-[10px] text-[11px] leading-[18px] text-grey-600 border-b-[1px] border-b-[#E5EAEE] w-full'>
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='py-[10px] text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE] w-full'
                  colSpan={3}
                >
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
                <TableCell className='py-[10px] text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE] w-full'>
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
                <TableCell className='py-[10px] text-[11px] leading-[18px] text-grey-600 border-b-[1px] border-b-[#E5EAEE] w-full'>
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='py-[10px] text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE] w-full'
                  colSpan={3}
                >
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
                <TableCell className='py-[10px] text-[11px] leading-[18px] text-grey-900 border-b-[1px] border-b-[#E5EAEE] w-full'>
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
                <TableCell className='py-[10px] text-[11px] leading-[18px] text-grey-600 border-b-[1px] border-b-[#E5EAEE] w-full'>
                  <Skeleton className='w-full h-[20px]' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </Card>
  );
};
