import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTelegramSettings } from 'src/common/api/channels-settings';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { catchError } from 'src/common/utils/catchError';
import { Button } from 'src/ui/Button/Button';
import { useSearchParams } from 'src/common/hooks';
import { useToast } from 'src/ui/Toast/use-toast';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { FaSpinner } from 'react-icons/fa';
import { AppStateType } from 'src/store';

const TelegramForm: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { setSearchParams } = useSearchParams();
  const { toast } = useToast();
  const { user } = useSelector((state: AppStateType) => state.user);

  const { mutate: saveTelegramSettings, isLoading: isSaving } = useMutation({
    mutationFn: async () => {
      return setTelegramSettings();
    },
    onSuccess: data => {
      toast({ title: 'Bot was successfully created!', variant: 'success' });
      dispatch(updateUserLocaly({ expert: { settings: { telegramSettings: data } } }));
      dispatch(nextStep(1));
    },
    onError: e => {
      catchError(e);
    },
  });

  const handleGoBack = () => {
    setSearchParams({ channel: '' });
  };

  return (
    <div className='space-y-6 sticky top-0'>
      <div className='flex flex-col gap-[30px]'>
        <div className='space-y-2 mx-auto flex gap-[15px]'>
          <Button
            variant={'primary'}
            onClick={() => saveTelegramSettings()}
            disabled={isSaving || !!user?.expert?.settings?.telegramSettings?.botToken}
          >
            {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            Create bot
          </Button>
          <Button
            type='button'
            className='h-[36px] w-[70px] px-[15px] py-[5px] bg-light text-grey-600 rounded-[6px] ml-auto'
            onClick={() => {
              handleGoBack();
            }}
          >
            {t('onboarding.common.backButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { TelegramForm };
