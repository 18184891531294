import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { OnboardingQuestion } from 'src/pages/Onboarding/Subpages/Common';
import { AppStateType } from 'src/store';
import { Button } from 'src/ui/Button/Button';

interface TelegramFormProps {
  onClick: () => void;
  isSaving: boolean;
}

const TelegramForm: React.FC<TelegramFormProps> = ({ onClick, isSaving }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: AppStateType) => state.user);

  return (
    <div className='space-y-6 sticky top-0'>
      <div className='flex flex-col gap-[30px]'>
        <div className='space-y-2 mx-auto'>
          <Button
            variant={'primary'}
            onClick={onClick}
            disabled={isSaving || !!user?.expert?.settings?.telegramSettings?.botToken}
          >
            {isSaving && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            Create bot
          </Button>
        </div>
      </div>
      <OnboardingQuestion text={t('onboarding.common.difficulties', { connection: 'Telegram' })} />
    </div>
  );
};

export { TelegramForm };
