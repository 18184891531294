import React from 'react';
import { FaQuestion, FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/ui/Button/Button';
import { nextStep, previousStep, setPreviewMode } from 'src/store/onboarding/onboarding.slice';
import { AppStateType } from 'src/store';
import { FileInput } from 'src/ui/FileInput/FileInput';
import { selectUser } from 'src/store/user/user.slice';
import { useTranslation, Trans } from 'react-i18next';
import { BrandLogoPlaceholder } from 'src/components/BrandLogoPlaceholder';
import { AvatarPlaceholder } from 'src/components/AvatarPlaceholder';
import { FaXmark } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';
import { cn } from 'src/common/utils';
import PhoneMockup from 'src/common/assets/phone.jpg';

interface OnboardingWrapperProps {
  headerText: string;
  subheaderText: string;
  isSubmitDisabled?: boolean;
  onSubmit?: () => void;
  onAlternativeSubmit?: () => void;
  customSubmitText?: string;
  blockNextStep?: boolean;
  icon?: IconType;
  isLoading?: boolean;
}

export const OnboardingWrapper: React.FC<OnboardingWrapperProps> = ({
  headerText,
  subheaderText,
  customSubmitText,
  isSubmitDisabled,
  onSubmit,
  onAlternativeSubmit,
  children,
  blockNextStep,
  icon: Icon,
  isLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { step, lastStep } = useSelector((state: AppStateType) => state.onboarding);

  const handleSubmitClick = () => {
    onSubmit?.();
    if (!blockNextStep) dispatch(nextStep(1));
  };

  return (
    <div className='flex flex-col items-center'>
      <h1 className='header-1 text-center'>{t(headerText)}</h1>
      <div className='body mt-[7px] text-center flex flex-row items-center gap-1 flex-wrap'>
        <Trans
          i18nKey={subheaderText}
          components={{
            ...(Icon && {
              1: <Icon className='text-[#635BFF] text-[38px] mt-[2.4px] shrink' />,
              2: <span />,
            }),
          }}
        />
      </div>
      <div className='md:min-w-[400px] md:w-[70%] w-full mt-[24px]'>
        {children}
        {step < OnboardingSteps.SUBSCRIPTIONS ? (
          <div className='flex mt-[30px] md:hidden'>
            <Button
              variant='primary_light'
              className='w-full md:max-w-[192px] grow'
              onClick={() => dispatch(setPreviewMode(true))}
            >
              {t('onboarding.common.mobilePreview')}
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div className='flex gap-[16px] mt-[15px] md:justify-between md:gap-[0px] md:mt-[30px] items-center'>
          {lastStep !== 0 ? (
            step !== OnboardingSteps.PAYMENTS ? (
              <Button
                disabled={isSubmitDisabled}
                variant='primary'
                className='w-full md:w-auto md:min-w-[192px]'
                onClick={handleSubmitClick}
              >
                {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                {customSubmitText || t('onboarding.common.saveButton')}
              </Button>
            ) : (
              <div className='flex gap-[16px] w-full md:w-auto'>
                <Button
                  disabled={isSubmitDisabled}
                  variant='primary'
                  className='w-full md:w-auto md:min-w-[192px]'
                  onClick={handleSubmitClick}
                >
                  {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                  {customSubmitText || t('onboarding.common.saveButton')}
                </Button>
                <Button variant='primary' onClick={onAlternativeSubmit} disabled={isLoading}>
                  {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
                  Skip
                </Button>
              </div>
            )
          ) : null}
          {step !== 0 ? (
            <Button
              className='h-[36px] w-[70px] px-[15px] py-[5px] bg-light text-grey-600 rounded-[6px]'
              onClick={() => dispatch(previousStep(1))}
            >
              {t('onboarding.common.backButton')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------

interface OnboardingQuestionProps {
  text?: string;
  className?: string;
  i18nKey?: string;
}

export const OnboardingQuestion: React.FC<OnboardingQuestionProps> = ({
  text,
  className,
  i18nKey,
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn('flex items-center justify-center mt-[44px] gap-[14px]', className)}>
      <FaQuestion className='text-primary' />
      {text ? (
        <div className='flex flex-col font-medium text-[14px] leading-[22px]'>
          <div>{text}</div>
          <div>
            <Trans
              i18nKey={'onboarding.common.demo'}
              components={{
                1: (
                  <a
                    href={`mailto:${process.env.REACT_APP_TARGET_EMAIL}`}
                    className='text-primary cursor-pointer'
                  />
                ),
              }}
            />
          </div>
        </div>
      ) : null}
      {i18nKey ? (
        <div className='flex flex-col text-[14px]'>
          <Trans
            i18nKey={i18nKey}
            components={{
              1: <div />,
              2: (
                <a
                  href={`mailto:${process.env.REACT_APP_TARGET_EMAIL}`}
                  className='text-primary cursor-pointer'
                />
              ),
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

// --------------------------------------------------------------

interface BrandingPhotoUploadingProps {
  handleOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    url: string,
    type: 'logo' | 'avatar',
  ) => void;
  handleOnDelete: (url: string, type: 'logo' | 'avatar') => void;
  handleCropperApply?: (url: string, file: File, type: 'logo' | 'avatar') => void;
}

export const BrandingPhotoUploading: React.FC<BrandingPhotoUploadingProps> = ({
  handleOnChange,
  handleOnDelete,
  handleCropperApply,
}) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  return (
    <div className='flex gap-[30px] md:gap-[16px] flex-col items-center md:flex-row justify-center'>
      <div className='w-full md:w-fit'>
        <h3 className='mt-0 mb-[15px] text-grey-900 font-[700] text-[16px] leading-[23px] text-left'>
          {t('onboarding.branding.avatarHeader')}
        </h3>
        <FileInput
          defaultUrl={user?.avatarUrl ?? ''}
          onChange={(e, url) => handleOnChange(e, url, 'avatar')}
          onDelete={url => handleOnDelete(url as string, 'avatar')}
          onCropperApply={(file, url) => handleCropperApply?.(url, file, 'avatar')}
        >
          <AvatarPlaceholder />
        </FileInput>
      </div>
      <div className='w-full md:w-fit'>
        <h3 className='mt-0 mb-[15px] text-grey-900 font-[700] text-[16px] leading-[23px] text-left'>
          {t('onboarding.branding.brandHeader')}
        </h3>
        <FileInput
          key={user?.expert?.brandLogoUrl}
          cropperMode='rectangle'
          defaultUrl={user?.expert?.brandLogoUrl ?? undefined}
          onChange={(e, url) => handleOnChange(e, url, 'logo')}
          onDelete={url => handleOnDelete(url as string, 'logo')}
          onCropperApply={(file, url) => handleCropperApply?.(url, file, 'logo')}
        >
          <BrandLogoPlaceholder />
        </FileInput>
      </div>
    </div>
  );
};

// ----------------------------------------------------------

export interface PreviewCommonWrapper {
  text: string;
  children: React.ReactNode;
}

export const PreviewCommonWrapper: React.FC<PreviewCommonWrapper> = ({ text, children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { previewMode, step } = useSelector((state: AppStateType) => state.onboarding);

  return (
    <div className='flex flex-col items-center w-full min-h-fit gap-[25px] md:gap-[42px] max-w-[90vw] md:max-w-full relative'>
      <div
        className={cn('flex w-full justify-center items-start', {
          'justify-between': previewMode,
        })}
      >
        {step !== OnboardingSteps.CONGRATULATIONS ? (
          <div
            className={cn('text-[14px] text-grey-900', {
              'text-[18px] font-bold': previewMode,
            })}
          >
            {t(text)}
          </div>
        ) : null}
        {previewMode ? (
          <div onClick={() => dispatch(setPreviewMode(false))} className='ml-2 cursor-pointer'>
            <FaXmark className='text-grey-500 w-[22px] h-[22px] mt-[4px]' />
          </div>
        ) : null}
      </div>
      {children}
    </div>
  );
};

// -----------------------------------------------------------

interface PreviewMobileWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const PreviewMobileWrapper: React.FC<PreviewMobileWrapperProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const { previewMode } = useSelector((state: AppStateType) => state.onboarding);

  return (
    <div {...props}>
      {previewMode ? (
        <div
          onClick={() => dispatch(setPreviewMode(false))}
          className='cursor-pointer text-right mb-[20px]'
        >
          <FaXmark fontSize={22} className='text-grey-500' />
        </div>
      ) : null}
      <div
        className='relative w-[337px] mt-[15px] md:mt-0 h-[690px] rounded-[48px] px-[20px] pt-[45px] py-[20px] bg-stone-300'
        style={{
          backgroundImage: `url(${PhoneMockup})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div
          className='flex flex-col justify-between h-full w-full rounded-[24px] px-[10px] py-[15px]'
          style={{
            backgroundColor: '#FFFFFF',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
