import React from 'react';
import { messengerButtonIcons } from './MessengerButton.config';
import { cn } from 'src/common/utils/cn';
import { Button } from '../Button/Button';
import { VariantProps, cva } from 'class-variance-authority';

export const messengerButtonVariants = cva('relative px-[12px] py-[12px] border-dashed w-full', {
  variants: {
    app: {
      whatsapp: 'text-whatsapp bg-whatsapp border-whatsapp',
      telegram: 'text-telegram bg-telegram border-telegram',
      messenger: 'text-messenger bg-messenger border-messenger',
      instagram: 'text-instagram bg-instagram bg-instagram',
    },
    variant: {
      default: 'bg-white',
      solid:'border-[2px] border-solid',
      filled: 'border-solid text-white',
      disabled: 'text-grey-500',
    },
  },
  defaultVariants: {
    variant: 'default',
    app: 'whatsapp',
  },
});

export interface MessengerButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof messengerButtonVariants> {
  isEnabled?: boolean;
  filled?: boolean;
  bgColor?: string;
  templateColor?: string;
}

export const MessengerButton: React.FC<MessengerButtonProps> = ({
  filled,
  isEnabled = true,
  variant,
  app,
  className,
  children,
  templateColor,
  ...props
}) => {
  const Icon = messengerButtonIcons[app ?? 'instagram'];

  return (
    <Button
      variant='category'
      className={cn(messengerButtonVariants({ variant, app, className }), {
        'text-grey-500 bg-white border-grey-500 border-dashed': !isEnabled,
      })}
      style={{
        ...(templateColor && templateColor !== 'default'
          ? {
              ...(variant === 'filled' && { background: templateColor }),
              borderColor: templateColor,
            }
          : {}),
      }}
      {...props}
    >
      <Icon
        className={`w-[21px] h-[21px]`}
        style={{
          ...(variant === 'default' && {
            color: templateColor === 'default' ? 'currentColor' : templateColor,
          }),
        }}
      />
      <span
        className={cn({ '!text-white': filled })}
        style={{
          ...(variant === 'default' && {
            color: templateColor === 'default' ? 'currentColor' : templateColor,
          }),
        }}
      >
        {children}
      </span>
      <div
        className={`absolute right-1 sm:right-2 w-[36px] h-[22.5px] 
      ${
        !isEnabled
          ? 'bg-light text-grey-500 rounded-[6px] text-[9px] leading-[22.5px] font-semibold'
          : ''
      }
      `}
      >
        {isEnabled ? '' : 'Soon'}
      </div>
    </Button>
  );
};
