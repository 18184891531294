import React, { useState } from 'react';
import { MessengerType } from 'src/common/interfaces';
import { extendedMessengerName } from 'src/common/utils/extendMessengerName';
import { useMutation } from '@tanstack/react-query';
import { removeInstagramSettings } from 'src/common/api/channels-settings';
import { useDispatch } from 'react-redux';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { FaSpinner } from 'react-icons/fa6';
import { RemoveAlert } from './RemoveAlert';

const RemoveInstagramAlert: React.FC<{ isTheLast?: boolean }> = ({ children, isTheLast }) => {
  const [open, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation({
    mutationFn: removeInstagramSettings,
    onSuccess: data => {
      dispatch(updateUserLocaly({ expert: { settings: { instagramSettings: data } } }));
    },
    onSettled: () => {
      setIsOpen(false);
    },
  });

  return (
    <RemoveAlert
      open={open}
      onOpenChange={setIsOpen}
      messengerType={MessengerType.INSTAGRAM}
      isLoading={isLoading}
      onRemove={mutate}
      isTheLast={isTheLast}
    >
      {children}
    </RemoveAlert>
  );
};

export { RemoveInstagramAlert };
