import * as React from 'react';
import type { FileWithPreview } from 'src/common/interfaces';
import { useDropzone, type Accept, type FileRejection, type FileWithPath } from 'react-dropzone';
import type { FieldPath, FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';
import { toast } from 'src/ui/Toast/use-toast';

import 'cropperjs/dist/cropper.css';

import {
  FaCropSimple as CropIcon,
  FaArrowRotateLeft as ResetIcon,
  FaTrash as TrashIcon,
  FaUpload as UploadIcon,
} from 'react-icons/fa6';
import { FaTimes as Cross2Icon } from 'react-icons/fa';

import { cn, formatBytes } from 'src/common/utils';
import { Button } from 'src/ui/Button/Button';
import { Dialog, DialogContent, DialogTrigger } from 'src/ui/Dialog/Dialog';
import { useTranslation } from 'react-i18next';

interface FileDialogProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends React.HTMLAttributes<HTMLDivElement> {
  name: TName;
  setValue: UseFormSetValue<TFieldValues>;
  accept?: Accept;
  maxSize?: number;
  maxFiles?: number;
  files: FileWithPreview[] | null;
  setFiles: React.Dispatch<React.SetStateAction<FileWithPreview[] | null>>;
  isUploading?: boolean;
  disabled?: boolean;
}

export function FileDialog<TFieldValues extends FieldValues>({
  name,
  setValue,
  accept = {
    '*/*': [],
  },
  maxSize = 1024 * 1024 * 2,
  maxFiles = 1,
  files,
  setFiles,
  isUploading = false,
  disabled = false,
  className,
  ...props
}: FileDialogProps<TFieldValues>) {
  const { t } = useTranslation();

  const onDrop = React.useCallback(
    (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
      acceptedFiles.forEach(file => {
        const fileWithPreview = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        setFiles(prev => [...(prev ?? []), fileWithPreview]);
      });

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach(({ errors }) => {
          if (errors[0]?.code === 'file-too-large') {
            toast({
              title: t('chats.attachments.sizeError') + ` ${formatBytes(maxSize)}`,
              variant: 'destructive',
            });
            return;
          }
          errors[0]?.message && toast({ title: errors[0].message, variant: 'destructive' });
        });
      }
    },

    [maxSize, setFiles],
  );

  // Register files to react-hook-form
  React.useEffect(() => {
    setValue(name, files as PathValue<TFieldValues, Path<TFieldValues>>);
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
    maxFiles,
    multiple: maxFiles > 1,
    disabled,
  });

  // Revoke preview url when component unmounts
  React.useEffect(() => {
    return () => {
      if (!files) return;
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, []);

  return (
    <>
      {' '}
      <p className='absolute left-5 top-4 text-base font-medium text-muted-foreground'>
        {t('chats.attachments.title')}
      </p>
      <div
        {...getRootProps()}
        className={cn(
          'group relative mt-8 grid h-48 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25',
          'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          isDragActive && 'border-muted-foreground/50',
          disabled && 'pointer-events-none opacity-60',
          className,
        )}
        {...props}
      >
        <input {...getInputProps()} />
        {isUploading ? (
          <div className='group grid w-full place-items-center gap-1 sm:px-10'>
            <UploadIcon className='size-9 animate-pulse text-muted-foreground' aria-hidden='true' />
          </div>
        ) : isDragActive ? (
          <div className='grid place-items-center gap-2 text-muted-foreground sm:px-5'>
            <UploadIcon
              className={cn('size-8', isDragActive && 'animate-bounce')}
              aria-hidden='true'
            />
            <p className='text-base font-medium'>{t('chats.attachments.drop')}</p>
          </div>
        ) : (
          <div className='grid place-items-center gap-1 sm:px-5'>
            <UploadIcon className='size-8 text-muted-foreground' aria-hidden='true' />
            <p className='mt-2 text-base font-medium text-muted-foreground'>
              {t('chats.attachments.dragNDrop')}
            </p>
            <p className='text-sm text-slate-500'>
              {t('chats.attachments.sizeTip')} {formatBytes(maxSize)}
            </p>
          </div>
        )}
      </div>
      <p className='text-center text-xs font-medium text-muted mt-2'>
        {t('chats.attachments.countTip')} {maxFiles} {maxFiles === 1 ? 'file' : 'files'}
      </p>
      {files?.length ? (
        <div className='grid gap-5 my-4'>
          {files?.map((file, i) => (
            <FileCard key={i} i={i} files={files} setFiles={setFiles} file={file} />
          ))}
        </div>
      ) : null}
      {files?.length ? (
        <Button
          type='button'
          variant='outlined'
          className='mt-2.5 w-full'
          onClick={() => setFiles(null)}
        >
          <TrashIcon className='mr-2 size-4' aria-hidden='true' />
          {t('chats.attachments.removeAll')}
          <span className='sr-only'> {t('chats.attachments.removeAll')}</span>
        </Button>
      ) : null}
    </>
  );
}

interface FileCardProps {
  i: number;
  file: FileWithPreview;
  files: FileWithPreview[] | null;
  setFiles: React.Dispatch<React.SetStateAction<FileWithPreview[] | null>>;
}

function FileCard({ i, file, files, setFiles }: FileCardProps) {
  const { t } = useTranslation();

  return (
    <div className='relative flex items-center justify-between gap-10'>
      <div className='flex items-center gap-2'>
        {file.type.includes('image') && (
          <img
            src={file.preview}
            alt={file.name}
            className='size-10 shrink-0 rounded-md'
            width={40}
            height={40}
            loading='lazy'
          />
        )}
        <div className='flex flex-col'>
          <p className='line-clamp-1 text-sm font-medium text-muted-foreground'>
            {file.name.slice(0, 45)}
          </p>
          <p className='text-xs text-slate-500'>{(file.size / 1024 / 1024).toFixed(2)}MB</p>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <Button
          type='button'
          variant='outlined'
          className='size-7'
          onClick={() => {
            if (!files) return;
            setFiles(files.filter((_, j) => j !== i));
          }}
        >
          <Cross2Icon className='size-4 ' aria-hidden='true' />
          <span className='sr-only'>{t('chats.attachments.removeFile')}</span>
        </Button>
      </div>
    </div>
  );
}
