import React, { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Chat, ChatStatus, ChatMessage } from 'src/common/interfaces';
import { selectUser } from 'src/store/user/user.slice';
import { Trans, useTranslation } from 'react-i18next';

import ChatItem from 'src/ui/ChatItem/ChatItem';

import SearchInput from 'src/ui/SearchInput/SearchInput';
import TabSwitcher from 'src/ui/TabSwitch/TabSwitch';

import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBrowserNotificationsStore } from 'src/store/notifications/useBrowserNotificationsStore';
import { ROUTES } from 'src/router/routes';
import { cn } from 'src/common/utils/cn';
import { useOnboardingStatus, useScreenDetect } from 'src/common/hooks';
import { useQuery } from '@tanstack/react-query';
import { getFilteredChats } from 'src/common/api/chat';
import { PageTopMenu } from 'src/components/PageTopMenu';
import { useChatStore } from 'src/store/chat/chatStore';
import { useDebounce, useSearchParams } from 'src/common/hooks';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'src/ui/Resizable/ResizablePanel';
import EmptyChat from 'src/common/assets/empty-chat.png';
import { Skeleton } from 'src/ui/Skeleton/Skeleton';
import { Button } from 'src/ui/Button/Button';
import { useMobileDetect } from 'src/common/hooks/useMobileDetect';

export const MessagesPage = () => {
  const { activeChats, requestedChats, closedChats, inboxCount, requestsCount } = useChatStore();

  const { searchParams, setNewSearchParams } = useSearchParams();
  const navigate = useNavigate();

  const tabValue = searchParams.get('chatStatus') || ChatStatus.ACTIVE;
  const nameValue = useDebounce(searchParams.get('clientName') || '', 500);

  const { data: filteredChats } = useQuery(['filtered-chats', nameValue, tabValue], {
    queryFn: () => {
      return getFilteredChats(searchParams.toString());
    },
    enabled: !!tabValue && !!nameValue,
  });

  const { chatId } = useParams();
  const { isTargetScreen } = useScreenDetect('mobile');
  const { isMobile } = useMobileDetect();

  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const { requestPermission } = useBrowserNotificationsStore();

  useEffect(() => {
    requestPermission();
  }, []);

  const handleSelectedChatChange = useCallback(
    (chatId: Chat['id'], status: string) => {
      navigate({
        pathname: !isMobile ? `/messages/${chatId}` : '/messages',
        search: `?chatStatus=${status}`,
      });
    },
    [navigate, isMobile],
  );

  useEffect(() => {
    if (!searchParams.get('chatStatus') && (activeChats.length || requestedChats.length)) {
      const id = activeChats.length ? activeChats[0].id : requestedChats[0].id;
      if (id && !isMobile) {
        navigate({
          pathname: `/messages/${id}`,
          search: `?chatStatus=active`,
        });
      }
    }
  }, [searchParams, activeChats, requestedChats, isMobile]);

  if (!user) {
    return <></>;
  }

  if (isTargetScreen && chatId) {
    return <Outlet />;
  }

  return (
    <div className='w-full h-full'>
      <div className='w-full h-full flex flex-row'>
        <ResizablePanelGroup direction='horizontal'>
          <ResizableProvider>
            <div className='w-full flex flex-col h-full border-r border-l border-grey-2'>
              <div className='md:px-[15px] py-[15px]'>
                <PageTopMenu title={t('chats.title')} className='text-center lg:text-left' />
              </div>
              <div className='px-[15px] mb-[15px]'>
                <ChatSearchInput />
              </div>
              <TabSwitcher
                commonBorder
                className='px-2'
                value={tabValue}
                onChange={value => {
                  if (value === 'active' && activeChats.length) {
                    handleSelectedChatChange(activeChats[0].id, value.toString());
                  } else if (value === 'requested' && requestedChats.length) {
                    handleSelectedChatChange(requestedChats[0].id, value.toString());
                  } else if (value === 'closed' && closedChats.length) {
                    handleSelectedChatChange(closedChats[0].id, value.toString());
                  } else {
                    setNewSearchParams({
                      chatStatus: value.toString(),
                    });
                  }
                }}
                tabs={[
                  {
                    label: t('chats.tabs.inbox'),
                    count: inboxCount + requestsCount,
                    content: (
                      <ChatList
                        chats={[
                          ...(nameValue ? filteredChats ?? [] : activeChats ?? []),
                          ...requestedChats,
                        ]}
                        chatId={chatId}
                      />
                    ),
                    value: ChatStatus.ACTIVE,
                    className: 'text-[14px]',
                  },
                  {
                    label: t('chats.tabs.requests'),
                    count: requestsCount,
                    content: <ChatList chats={requestedChats} chatId={chatId} />,
                    disabled: !requestedChats?.length,
                    value: ChatStatus.REQUESTED,
                    className: 'text-[14px]',
                  },
                  {
                    label: t('chats.tabs.completed'),
                    content: <ChatList chats={closedChats} chatId={chatId} />,
                    disabled: !closedChats?.length,
                    value: ChatStatus.CLOSED,
                    className: 'text-[14px]',
                  },
                ]}
              ></TabSwitcher>
            </div>
          </ResizableProvider>
          <ResizableHandle />
          <ResizablePanel>
            <Outlet />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </div>
  );
};

const ResizableProvider = ({ children }: { children: React.ReactNode }) => {
  const { isTargetScreen: isMobile } = useScreenDetect('mobile');

  if (isMobile) return <>{children}</>;

  return (
    <ResizablePanel defaultSize={25} className='md:max-w-[450px]  min-w-[300px] md:min-w-[375px]'>
      {children}
    </ResizablePanel>
  );
};

type NarrowedClient = Pick<Chat['client'], 'messengerType' | 'avatar' | 'name'>;

const ChatSearchInput = () => {
  const { searchParams, setNewSearchParams } = useSearchParams();

  const nameValue = searchParams.get('clientName') || '';

  return (
    <SearchInput
      inputClassName='w-full'
      className='max-w-full'
      value={nameValue}
      onChange={e => {
        setNewSearchParams({
          clientName: e.target.value,
        });
      }}
    />
  );
};

const ChatList = ({
  chats,
  chatId,
}: {
  chats: (Pick<Chat, 'id' | 'unreadCount' | 'messengerType' | 'status'> & {
    client: NarrowedClient;
    messages?: ChatMessage[];
  })[];
  chatId?: string;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { messengerStatus, isCompleted, handleBackToOnboarding, status } = useOnboardingStatus();

  return (
    <div className='h-[calc(100vh-280px)] lg:h-[calc(100vh-181px)] overflow-scroll'>
      {chats &&
        chats.map((chat, index) => (
          <Link
            key={chat.id}
            to={{ pathname: `/${ROUTES.CHATS}/${chat.id}`, search: location.search }}
          >
            <ChatItem
              username={chat.client.name}
              userImage={chat.client.avatar}
              unreadCount={chat.unreadCount}
              messageVariant={chat.messages?.[0]?.variant}
              messengerType={chat.messengerType}
              attachments={chat.messages?.[0]?.attachments}
              date={chat.messages?.[0]?.createdAt}
              chatVariant={chat.status}
              message={chat.messages?.slice(-1)[0]?.body}
              className={cn({ 'bg-primary': chatId === chat.id })}
              whitespaceNormal={false}
            />
          </Link>
        ))}
      {messengerStatus?.filter(status => status).length < 2 && (
        <Link to={`/${ROUTES.COMMUNICATION_CHANNELS}`}>
          <ChatItem
            className=''
            username={t('chats.tabs.moreChannels.title')}
            userImage={EmptyChat}
            unreadCount={0}
            date={''}
            message={t('chats.tabs.moreChannels.subtitle')}
            whitespaceNormal={true}
          />
        </Link>
      )}
      {!chats.length ? (
        <>
          <div
            className={cn('px-[15px] pb-[15px] flex justify-between items-center gap-[13px]', {
              'pt-[15px]': messengerStatus?.filter(status => status).length > 1,
            })}
          >
            <Skeleton className='w-[60px] h-[60px] rounded-full animate-none' />
            <div className='grow'>
              <Skeleton className='w-[117px] h-[13px] rounded-[18px] animate-none' />
              <Skeleton className='w-[81px] h-[9px] rounded-[18px] mt-[10px] animate-none' />
            </div>
            <div className='self-start mt-[15px]'>
              <Skeleton className='w-[81px] h-[9px] rounded-[18px] animate-none' />
            </div>
          </div>
          <div className='px-[15px] pb-[15px]  flex justify-between items-center gap-[13px]'>
            <Skeleton className='w-[60px] h-[60px] rounded-full animate-none' />
            <div className='grow'>
              <Skeleton className='w-[117px] h-[13px] rounded-[18px] animate-none' />
              <Skeleton className='w-[81px] h-[9px] rounded-[18px] mt-[10px] animate-none' />
            </div>
            <div className='self-start mt-[15px]'>
              <Skeleton className='w-[81px] h-[9px] rounded-[18px] animate-none' />
            </div>
          </div>
        </>
      ) : null}
      {!isCompleted || !chats.length ? (
        <div className='flex w-full justify-center md:hidden px-[30px]'>
          <div className='max-w-[600px] flex flex-col gap-6 text-center items-center'>
            <h1 className='text-[64px]'>💬</h1>
            {!isCompleted ? (
              <>
                <p className='text-md md:text-lg'>{t('chats.empty.subtitle')}</p>
                <Button
                  variant='primary'
                  className='px-[30px]'
                  onClick={() => handleBackToOnboarding()}
                >
                  {t('chats.empty.button')}
                </Button>
              </>
            ) : (
              <p>
                <Trans
                  i18nKey={'chats.empty.noChats'}
                  components={{
                    1: <Link to={`/${ROUTES.LINKS}`} className='inline text-primary' />,
                  }}
                />
              </p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
