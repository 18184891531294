import React from 'react';
import { cn } from 'src/common/utils';
import { FaCheck as CheckIcon } from 'react-icons/fa6';

type ColorPickerProps = {
  value?: string | null;
  onChange: (value: string) => void;
  colors: string[];
  className?: string;
};

const ColorPicker = ({ value, onChange, colors, className }: ColorPickerProps) => {
  return (
    <div className={cn('flex gap-3 flex-wrap', className)}>
      {colors.map(color => {
        const selected = value === color;
        return (
          <button
            type='button'
            className={cn('w-10 h-10 rounded-full flex items-center justify-center', {
              'outline outline-3 outline-primary': selected,
            })}
            style={{ backgroundColor: color }}
            key={color}
            onClick={() => onChange(color)}
          >
            {selected && <CheckIcon className='w-5 h-5 text-white' />}
          </button>
        );
      })}
    </div>
  );
};

export { ColorPicker };
