import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { BrandingPhotoUploading, OnboardingWrapper } from './Common';
import { CardPicker } from 'src/ui/CardPicker/CardPicker';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { useImagesUpload } from 'src/common/hooks';
import { useThematicTemplateStore } from 'src/store/expertise/useThematicTemplateStore';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldError } from 'src/components/FieldError';
import { useMutation } from '@tanstack/react-query';
import { updateUser } from 'src/common/api/user';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { useOnboardingContext } from 'src/context/Onboarding.context';
import { ThematicTemplateConfig } from 'src/common/interfaces/thematicTemplate';
import { thematicTemplateConfigShema } from 'src/common/utils/schema/branding.schema';
import { CheckBox } from 'src/ui/CheckBox/CheckBox';
import { ColorPicker } from 'src/ui/ColorPicker/ColorPicker';
import { customColorTemplateId, defaultThemplateId } from 'src/common/configs/thematic-templates';
import { deleteImages, uploadFiles } from 'src/common/api/file';

interface BrandingProps {}

type Inputs = z.infer<typeof thematicTemplateConfigShema>;

export const Branding: React.FC<BrandingProps> = () => {
  const { data: thematicTemplates } = useThematicTemplateStore();
  const { user } = useSelector((state: AppStateType) => state.user);

  const {
    images,
    handleOnChange,
    handleOnDelete,
    handleCropperApply,
    imagesToDelete,
    imagesToUpload,
  } = useImagesUpload(user);

  const { updateProfilePreview } = useOnboardingContext();

  const dispatch = useDispatch();

  const { mutate: updateUserBranding, isLoading } = useMutation({
    mutationFn: async (thematicTemplateConfig: Partial<ThematicTemplateConfig>) => {
      let res = null;

      if (imagesToDelete.length) {
        res = await deleteImages(imagesToDelete, user!.id);
      }
      if (imagesToUpload.length) {
        res = await uploadFiles(imagesToUpload, `user/${user!.id}/media`);
      }

      const data = await updateUser({
        id: user?.id!,
        expert: {
          userId: user?.id!,
          brandLogoUrl: user?.expert?.brandLogoUrl,
          thematicTemplateConfig: {
            ...thematicTemplateConfig,
            wasChangedByUser: true,
            wasIntentionallyChangedByUser: true,
          },
        },
      });

      return data;
    },
    onSuccess: data => {
      dispatch(updateUserLocaly(data));
      dispatch(nextStep(1));
    },
  });

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const cards = useMemo(() => {
    return [
      ...(thematicTemplates
        ?.sort((a, b) => a.thematicTemplateId - b.thematicTemplateId)
        .map(({ thematicTemplateId, translation }) => ({
          value: thematicTemplateId,
          label: translation,
        })) ?? []),
      { value: customColorTemplateId, label: 'common.thematicTemplate.otherColor' },
    ];
  }, [thematicTemplates]);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(thematicTemplateConfigShema),
    defaultValues: {
      whiteBackground: false,
    },
  });

  const { thematicTemplate, whiteBackground, color } = useWatch({
    control: control,
  });

  const getThematicTemplateById = useCallback(
    id => thematicTemplates?.find(t => t.thematicTemplateId === id),
    [thematicTemplates],
  );

  useEffect(() => {
    const template = getThematicTemplateById(thematicTemplate?.thematicTemplateId);

    updateProfilePreview({
      thematicTemplateConfig: {
        thematicTemplate: template,
        whiteBackground,
        color,
      },
    } as any);
  }, [thematicTemplate, whiteBackground, color, thematicTemplates]);

  useEffect(() => {
    reset({
      ...user?.expert?.thematicTemplateConfig,
      thematicTemplate: {
        thematicTemplateId:
          user?.expert?.thematicTemplateConfig?.thematicTemplateId || customColorTemplateId,
      },
    });
  }, [user?.expert?.thematicTemplateConfig]);

  const onSubmit = (vals: Inputs) => {
    const isCustomColor = vals.thematicTemplate.thematicTemplateId === customColorTemplateId;

    updateUserBranding({
      thematicTemplateId: isCustomColor ? null : vals.thematicTemplate.thematicTemplateId,
      color: isCustomColor ? vals.color : null,
      whiteBackground: vals.whiteBackground,
    });
  };

  return (
    <OnboardingWrapper
      headerText='onboarding.branding.header'
      subheaderText='onboarding.branding.subheader'
      isSubmitDisabled={!!!(images.avatar || user?.avatarUrl) || isLoading}
      onSubmit={() => buttonRef.current?.click()}
      isLoading={isLoading}
      blockNextStep
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <BrandingPhotoUploading
          handleOnChange={handleOnChange}
          handleOnDelete={handleOnDelete}
          handleCropperApply={handleCropperApply}
        />
        <Controller
          name='thematicTemplate'
          control={control}
          render={({ field }) => (
            <CardPicker
              headerText='Thematic templates'
              cards={cards}
              value={field.value?.thematicTemplateId}
              onChange={value => {
                const newValue = thematicTemplates?.find(
                  template => template.thematicTemplateId === value,
                ) || {
                  thematicTemplateId: customColorTemplateId,
                };
                'logoUrl' in newValue &&
                  dispatch(
                    updateUserLocaly({
                      id: user?.id!,
                      expert: {
                        userId: user?.id!,
                        brandLogoUrl: newValue.logoUrl,
                      },
                    }),
                  );
                field.onChange(newValue);
              }}
            />
          )}
        />
        {errors.thematicTemplate && (
          <FieldError error={errors.thematicTemplate.message}></FieldError>
        )}
        {thematicTemplate?.thematicTemplateId !== defaultThemplateId && (
          <Controller
            name='whiteBackground'
            control={control}
            render={({ field }) => (
              <div className='flex gap-2 items-center my-4'>
                <CheckBox
                  id='whiteBackground'
                  checked={field.value}
                  onCheckedChange={val => field.onChange(val)}
                />
                <label htmlFor='whiteBackground' className='text-grey-900'>
                  Use white background
                </label>
              </div>
            )}
          />
        )}
        {thematicTemplate?.thematicTemplateId === customColorTemplateId && (
          <Controller
            name='color'
            control={control}
            render={({ field }) => (
              <ColorPicker
                className='mt-4'
                value={field.value}
                onChange={val => {
                  field.onChange(val);
                }}
                colors={[
                  '#A4262C',
                  '#ED780D',
                  '#867365',
                  '#407855',
                  '#038387',
                  '#0078D4',
                  '#40587C',
                  '#8764B8',
                ]}
              />
            )}
          />
        )}

        <button className='hidden' ref={buttonRef}></button>
      </form>
    </OnboardingWrapper>
  );
};
