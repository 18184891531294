import React from 'react';
import { CommunicationChannelsWrapperLite } from '../CommunicationChannelsWrappers';
import { MessengerType } from 'src/common/interfaces';
import { useDispatch } from 'react-redux';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { useTranslation } from 'react-i18next';
import { setTelegramSettings } from 'src/common/api/channels-settings';
import { useMutation } from '@tanstack/react-query';
import { catchError } from 'src/common/utils/catchError';
import { TelegramForm } from './TelegramForm';
import { useToast } from 'src/ui/Toast/use-toast';

export default function TelegramSettingsPage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { toast } = useToast();

  const { mutate: saveTelegramSettings, isLoading: isSaving } = useMutation({
    mutationFn: async () => {
      return setTelegramSettings();
    },
    onSuccess: data => {
      toast({ title: 'Bot was successfully created!', variant: 'success' });
      dispatch(updateUserLocaly({ expert: { settings: { telegramSettings: data } } }));
    },
    onError: e => {
      catchError(e);
    },
  });

  return (
    <CommunicationChannelsWrapperLite
      isChanged={false}
      messengerType={MessengerType.TELEGRAM}
      activeElements={<></>}
      form={<TelegramForm onClick={() => saveTelegramSettings()} isSaving={isSaving} />}
      title={t('communicationChannels.telegram.title')}
    />
  );
}
