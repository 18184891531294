import { fetchBuilder } from 'src/services/basicFetch';
import { UserInterface } from 'src/store/user/user.slice';
import { MethodEnum } from '../enums';
import { RecursivePartial } from '../interfaces';
import { ExpertiseInterface } from 'src/store/onboarding/onboarding.slice';

const updateUser = (userData: RecursivePartial<UserInterface> & { isDomainCompleted?: boolean }) => {
  const { id, isDomainCompleted } = userData;
  return fetchBuilder(
    userData,
    `user/${userData.id}${isDomainCompleted ? `?isDomainCompleted=true` : ''}`,
    MethodEnum.PATCH,
  );
};

const updateUserProfile = (userData: RecursivePartial<UserInterface>): Promise<UserInterface> => {
  return fetchBuilder(userData, `user/${userData.id}/profile`, MethodEnum.PATCH);
};

const deleteUser = async (): Promise<UserInterface> => {
  return fetchBuilder({}, `assistant/delete-self`, MethodEnum.DELETE);
};

const updateThematicTemplateByExpertise = (
  expertiseValue: ExpertiseInterface['value'],
): Promise<UserInterface> => {
  return fetchBuilder({ expertiseValue }, `user/expertise-template`, MethodEnum.POST);
};

export { updateUser, updateUserProfile, deleteUser, updateThematicTemplateByExpertise };
