import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getAiChat, sendMessageToAi } from 'src/common/api/ai-chat';
import { AiChatFrom } from 'src/common/interfaces/ai-chat';
import { useTranslation } from 'react-i18next';
import { useChatStore } from 'src/store/chat/chatStore';
import { v4 as uuidv4 } from 'uuid';

import MessageInput from 'src/ui/MessageInput/MessageInput.chat';
import { MessagesWithDateIndicator } from '../Messages';
import SystemMessage from 'src/ui/ChatSystemMessage/SystemMessage';
import { ChatStatus, MessageVariant } from 'src/common/interfaces';
import { StoreAiChatMessage, useAiChatStore } from 'src/store/ai-chat/aiChatStore';
import { WritingPlaceholder } from 'src/ui/WritingPlaceholder/WritingPlaceholder';

import { AiMessage } from './AiMessage';
import { FaShieldAlt } from 'react-icons/fa';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FieldError } from 'src/components/FieldError';
import { ChatDateIndicator } from '../common/ChatDateIndicator';
import { AIChatPromptType, aiMessageSchema } from 'src/common/utils/schema/ai-message.schema';

type Inputs = z.infer<typeof aiMessageSchema>;

const aiPromptTypeCards = [
  { value: AIChatPromptType.RESPONSE, label: 'suggest response' },
  { value: AIChatPromptType.SUMMARIZE, label: 'summarize' },
];

const AiChat = () => {
  const { chatId } = useParams();

  const { activeChatInfo } = useChatStore();
  const { messages, setMessages, addMessage, removeMessage } = useAiChatStore();

  const { data } = useQuery(['ai-chat', chatId], {
    queryFn: () => getAiChat(chatId),
    onSuccess: data => {
      setMessages(data?.messages || []);
    },
  });

  const { mutate: sendMessage, isLoading: isGenerating } = useMutation({
    mutationFn: sendMessageToAi,
    onMutate: ({ message }) => {
      const generatedMessage: StoreAiChatMessage = {
        id: uuidv4(),
        body: message,
        createdAt: new Date(),
        updatedAt: new Date(),
        from: AiChatFrom.USER,
      };

      addMessage(generatedMessage);

      return { generatedMessage };
    },
    onSuccess: data => {
      addMessage(data);
    },
    onError(error, variables, context) {
      if (context) {
        removeMessage(context.generatedMessage.id);
      }
    },
  });

  const form = useForm<Inputs>({
    resolver: zodResolver(aiMessageSchema),
    defaultValues: {
      message: '',
    },
  });

  const { message } = useWatch({ control: form.control });

  if (!chatId || !activeChatInfo) return <></>;

  const onSubmit = ({ message, promptType }: Inputs) => {
    if (isGenerating) return;
    sendMessage({ chatId, message, promptType });
    form.reset();
  };

  const isClosed = activeChatInfo.status === ChatStatus.CLOSED;

  return (
    <>
      <PrivacyTip />
      <div className='w-full flex flex-col-reverse h-full px-3 gap-3 overflow-y-scroll overflow-x-clip scrollbar-hide bg-info-light pt-[178px] sm:pt-[64px]'>
        {isClosed && (
          <SystemMessage
            type={MessageVariant.CONVERSATION_COMPLETED}
            date={activeChatInfo.updatedAt || ''}
            className='w-3/4'
            isAiHelper={true}
          />
        )}
        {isGenerating && (
          <WritingPlaceholder className='max-w-[80px] bg-white' textClassName='!bg-grey-500' />
        )}
        {messages && (
          <MessagesWithDateIndicator
            messages={messages}
            chatCreatedAt={activeChatInfo.aiChat.createdAt}
            MessageComponent={AiMessage}
            props={{}}
          />
        )}
        {!!messages.length && (
          <ChatDateIndicator date={activeChatInfo.aiChat.createdAt} className='mt-4' />
        )}
      </div>
      <div className='w-full bg-info-light pt-4 sm:px-4 sm:pb-4'>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div
            className={
              'secondary w-full flex flex-col p-[20px] rounded-md bg-white focus-within:shadow-[0_0_4px_0_#009EF7_inset]  hover:shadow-[0_0_4px_#3F42544D_inset]'
            }
          >
            <Controller
              control={form.control}
              name='message'
              render={({ field }) => {
                return (
                  <MessageInput
                    value={field.value}
                    onChange={field.onChange}
                    isChatbot
                    onMessageSend={() => {
                      if (!isGenerating) form.handleSubmit(onSubmit)();
                    }}
                    placeholder='Your message to AI helper...'
                    disabled={isClosed}
                    disableSending={isGenerating}
                  />
                );
              }}
            />
          </div>
          <div>
            <FieldError className='mt-2' error={form.formState.errors.message?.message} />
          </div>
        </form>
      </div>
    </>
  );
};

const PrivacyTip = () => {
  const { t } = useTranslation();

  return (
    <div className='w-full bg-warning-light flex py-2 sm:py-5 px-6 sm:px-10 items-center gap-3 text-grey-900 fixed top-[112px] sm:top-[135px] h-[64px]'>
      <FaShieldAlt className='text-success w-5 h-5' />
      {t('chats.ai.privacyTip')}
    </div>
  );
};

export { AiChat };
