import React, { Dispatch, useMemo, useState } from 'react';
import { FaPaperclip, FaSpinner } from 'react-icons/fa';
import { Dialog, DialogContent, DialogTrigger } from 'src/ui/Dialog/Dialog';
import { ButtonProps, Button } from '../Button/Button';
import { FileDialog } from '../FileDialog/FileDialog';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FileWithPreview, MessengerType, UploadedFile } from 'src/common/interfaces';
import { useMutation } from '@tanstack/react-query';
import { uploadFiles } from 'src/common/api/file';
import { MethodEnum } from 'src/common/enums';
import { v4 as uuidv4 } from 'uuid';
import { isArrayOfFile } from 'src/common/utils/isArrayOfFile';
import { sendAttachmentsMessage } from 'src/common/api/chat';
import { useParams } from 'react-router-dom';
import { useChatStore } from 'src/store/chat/chatStore';
import { useTranslation } from 'react-i18next';
import { Textarea } from '../TextInput/TextInput';
import { attachmentsMessageSchema } from 'src/common/utils/schema/attachments-message.schema';
import * as z from 'zod';

const supportedMediaAmount = {
  [MessengerType.WHATSAPP]: 1,
  [MessengerType.INSTAGRAM]: 1,
  [MessengerType.MESSENGER]: 1,
  [MessengerType.TELEGRAM]: 1,
};

const messageBodySupport = {
  [MessengerType.WHATSAPP]: false,
  [MessengerType.INSTAGRAM]: false,
  [MessengerType.MESSENGER]: false,
  [MessengerType.TELEGRAM]: false,
};

type Inputs = z.infer<typeof attachmentsMessageSchema>;

const AttachmentsDialog = ({
  setIsAttachmentLoading,
  ...props
}: ButtonProps & {
  setIsAttachmentLoading?: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const { chatId } = useParams();
  const { addNewMessage, activeChatInfo } = useChatStore();

  const [open, setIsOpen] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[] | null>(null);

  const { mutate: sendMessage } = useMutation({
    mutationFn: (data: { attachments: UploadedFile[]; body: string }) =>
      sendAttachmentsMessage({ chatId: chatId!, ...data }),
    onSuccess: message => {
      addNewMessage(message);
    },
    onSettled: () => setIsAttachmentLoading?.(false),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: { files: { file: File; key: string }[]; body: string }) =>
      uploadFiles(data.files, 'storage/upload/many', 'cors', MethodEnum.POST),
    onMutate: () => setIsAttachmentLoading?.(true),
    onSuccess: (data, variables) => {
      const attachments = data?.map(({ url, ...rest }) => ({ src: url, ...rest }));

      attachments &&
        sendMessage({
          attachments: attachments as unknown as UploadedFile[],
          body: variables.body,
        });

      if (!attachments) {
        setIsAttachmentLoading?.(false);
      }
    },
    onError: () => setIsAttachmentLoading?.(false),
  });

  const form = useForm<Inputs>({
    resolver: zodResolver(attachmentsMessageSchema),
    defaultValues: {
      files: [],
      body: '',
    },
  });

  const handleSubmit = (vals: Inputs) => {
    if (isArrayOfFile(vals.files)) {
      mutate({ files: vals.files?.map(file => ({ file, key: uuidv4() })), body: vals.body || '' });
      setIsOpen(false);
      setFiles([]);
    }
  };

  const { maxFiles, bodySupport } = useMemo(() => {
    const messengerType = activeChatInfo?.messengerType;

    const maxFiles = messengerType ? supportedMediaAmount[messengerType] : 1;

    const bodySupport = messengerType ? messageBodySupport[messengerType] : false;

    return { maxFiles, bodySupport };
  }, [activeChatInfo?.messengerType]);

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button disabled={props.disabled}>
          <FaPaperclip className='text-3xl text-grey cursor-pointer  w-[22px] h-[22px]' />
          <span className='sr-only'>{t('chats.attachments.title')}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[480px]'>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Controller
            control={form.control}
            name='files'
            render={({ field }) => (
              <FileDialog
                disabled={!!(files && files.length >= maxFiles)}
                maxFiles={
                  activeChatInfo?.messengerType
                    ? supportedMediaAmount[activeChatInfo?.messengerType]
                    : 1
                }
                setValue={form.setValue}
                maxSize={1024 * 1024 * 10}
                name='files'
                files={files}
                setFiles={setFiles}
              />
            )}
          />
          {bodySupport && (
            <Controller
              control={form.control}
              name='body'
              render={({ field }) => (
                <Textarea {...field} placeholder='your message in here...' className='mt-2' />
              )}
            />
          )}
          <Button variant={'primary'} className='w-full mt-4' disabled={isLoading}>
            {isLoading && <FaSpinner className='animate-spin w-6 h-6 text-white mr-2' />}
            {t('common.send')}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export { AttachmentsDialog };
