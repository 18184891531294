import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProfilePreview } from 'src/ui/ProfilePreview/ProfilePreview';
import { OnboardingWrapper, PreviewCommonWrapper } from '../Common';
import { useSelector } from 'react-redux';
import { MessengerButton } from 'src/ui/MessengerButton/MessengerButton';
import { AboutYouForm } from './AboutYouForm';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { profileSchema } from 'src/common/utils/schema/profile.schema';
import * as z from 'zod';
import { useOnboardingContext } from 'src/context/Onboarding.context';
import { selectUser } from 'src/store/user/user.slice';
import { AppStateType } from 'src/store';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';

interface AboutYouLeftProps {}

type Inputs = z.infer<typeof profileSchema>;

export const AboutYouLeft: React.FC<AboutYouLeftProps> = () => {
  const aboutButtonRef = useRef<HTMLButtonElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { updateProfilePreview } = useOnboardingContext();
  const { user } = useSelector((state: AppStateType) => state.user);

  const form = useForm<Inputs>({
    resolver: zodResolver(profileSchema),
  });

  const { formState } = form;

  const { firstName, lastName, description } = useWatch({
    control: form.control,
  });

  useEffect(() => {
    updateProfilePreview({ firstName, lastName, description });
  }, [firstName, lastName, description]);

  const isFormDisabled = useMemo(() => {
    const { isDirty } = form.formState;

    return isDirty
      ? !firstName || !lastName || !description
      : !user?.firstName || !user.lastName || !user.expert?.description;
  }, [firstName, firstName, lastName, description, formState.isDirty, user]);

  return (
    <OnboardingWrapper
      isSubmitDisabled={isFormDisabled || isLoading}
      headerText='onboarding.aboutYou.header'
      subheaderText='onboarding.aboutYou.subheader'
      onSubmit={() => aboutButtonRef.current?.click()}
      isLoading={isLoading}
      blockNextStep
    >
      <AboutYouForm {...form} aboutButtonRef={aboutButtonRef} setIsLoading={setIsLoading} />
    </OnboardingWrapper>
  );
};

interface AboutYouRightProps {
  withCreds?: boolean;
}

export const AboutYouRight: React.FC<AboutYouRightProps> = ({ withCreds }) => {
  const user = useSelector(selectUser);
  const { profilePreview } = useOnboardingContext();
  const { step } = useSelector((state: AppStateType) => state.onboarding);

  const isPreview = step <= OnboardingSteps.BRANDING || step === OnboardingSteps.CONGRATULATIONS;

  return (
    <PreviewCommonWrapper text='onboarding.aboutYou.preview'>
      <ProfilePreview
        {...{ ...user, ...user?.expert, ...profilePreview, isPreview }}
        channelsTitle={'onboarding.profilePreview.channels'}
        showAllPossibleVariants={step !== OnboardingSteps.CONGRATULATIONS}
        withCreds={withCreds}
      ></ProfilePreview>
    </PreviewCommonWrapper>
  );
};
