import React, { useRef } from 'react';
import { useAutosizeTextArea } from 'src/common/hooks/useAutosizeTextArea';
import { cn } from 'src/common/utils/cn';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Tooltip } from '../Tooltip/Tooltip';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | null;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, disabled, error, ...props }, ref) => {
    return (
      <div className='relative w-full'>
        <input
          className={cn(
            `bg-light active:outline-none focus:outline-none focus:border-none focus:bg-primary-light rounded-md p-[15px] py-[8px] pb-[10px]
            text-[14px] leading-[22px]
            focus:shadow-[0_0_4px_0_#009EF7_inset] focused:bg-light w-full
            hover:shadow-[0_0_4px_#3F42544D_inset]`,
            {
              'opacity-80': disabled,
              'bg-danger-light pr-[35px]': !!error,
            },
            className,
          )}
          disabled={disabled}
          {...props}
        />
        {error ? (
          <div className='tooltip absolute top-[15px] right-[15px]'>
            <Tooltip text={error} className='normal-case font-normal'>
              <FaExclamationTriangle className='w-[18px] h-[18px] text-danger' />
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxHeight?: number;
  minHeight?: number;
  limit?: number;
  error?: string | null;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, disabled, minHeight, maxHeight, limit, value, onChange, error, ...props }, ref) => {
    const innerRef = useRef<HTMLTextAreaElement>(null);
    useAutosizeTextArea(innerRef.current, value as string, maxHeight, minHeight);

    const handleOnchange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (limit) {
        if (e.target.value.length <= limit) {
          onChange?.(e);
        }
      } else {
        onChange?.(e);
      }
    };

    return (
      <div className='w-full'>
        <div
          className={cn(
            `bg-light rounded-md p-[15px]
            text-[14px] leading-[22px]
            focus-within:bg-primary-light
            hover:shadow-[0_0_4px_0_rgba(63, 66, 84, 0.30)_inset]  
            focus-within:shadow-[0_0_4px_0_#009EF7_inset] focused:bg-light w-full flex items-start hover:shadow-[0_0_4px_#3F42544D_inset]`,
            { 'opacity-80': disabled, 'bg-danger-light': !!error },
            className,
          )}
        >
          <textarea
            className='focus:outline-none bg-transparent resize-none w-full h-full'
            ref={ref || innerRef}
            disabled={disabled}
            value={value}
            onChange={handleOnchange}
            {...props}
          ></textarea>
          {error ? (
            <div>
              <Tooltip text={error} className='normal-case font-normal'>
                <FaExclamationTriangle className='w-[18px] h-[18px] text-danger' />
              </Tooltip>
            </div>
          ) : null}
        </div>
        {limit ? (
          <div className='mt-[5px]'>
            <span className='text-grey-500 text-[12px] leading-[18px]'>
              {value?.toString().length || 0}/{limit}
            </span>
          </div>
        ) : null}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export { Textarea, TextInput };

export type { TextInputProps, TextareaProps };
