import React, { useMemo, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { FaPen, FaCircleInfo } from 'react-icons/fa6';
import { useMutation } from '@tanstack/react-query';
import { updateExpertDomainName } from 'src/common/api/expert';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { FieldError } from 'src/components/FieldError';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { useToast } from '../Toast/use-toast';
import { cn } from 'src/common/utils/cn';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { domainNameSchema } from 'src/common/utils/schema/domainName.schema';
import CopyButton from 'src/components/CopyButton';
import { useTranslation } from 'react-i18next';

export interface TextBufferProps {
  domainName: string;
  baseLink: string;
  setError: React.Dispatch<React.SetStateAction<Error | null>>;
  isPage?: boolean;
}

type Inputs = z.infer<typeof domainNameSchema>;

export const TextBuffer: React.FC<TextBufferProps> = ({
  domainName,
  baseLink,
  setError,
  isPage,
}) => {
  const dispatch = useDispatch();
  const { userToken, user } = useSelector((state: AppStateType) => state.user);
  const { toast } = useToast();

  const { t } = useTranslation();

  const formButton = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLSpanElement>(null);

  const {
    mutate: updateDomain,
    error,
    isLoading,
  } = useMutation({
    mutationFn: (domainName: string) => {
      return updateExpertDomainName(userToken!, user?.id!, domainName);
    },
    onSuccess: data => {
      reset({ domainName: data.domainName });
      setIsEditing(false);
      dispatch(updateUserLocaly({ expert: { userId: user?.id!, domainName: data.domainName } }));
      toast({ title: 'Link successfully changed', variant: 'success' });
      setError(null);
    },
    onError: error => {
      setError(error as Error);
    },
  });

  const [isEditing, setIsEditing] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(domainNameSchema),
    defaultValues: {
      domainName,
    },
  });
  const domainNameValue = watch('domainName');

  const link = useMemo(() => {
    return `${domainNameValue}.${baseLink}`;
  }, [domainNameValue]);

  const onSubmit = (vals: Inputs) => {
    if (!isDirty) setIsEditing(false);
    else {
      updateDomain(vals.domainName);
    }
  };

  return (
    <div
      className={cn(
        '-mx-[15px] px-[15px] py-[20px] md:mx-0 md:py-[20px] md:px-[35px] md:rounded-[9px] bg-light',
        {
          '-mx-[30px] px-[30px]': isPage,
        },
      )}
    >
      <div className='flex items-center flex-col gap-[15px]'>
        <div className='w-full relative'>
          <form action='' onSubmit={handleSubmit(onSubmit)}>
            <div
              onClick={e => inputRef.current?.focus()}
              className={cn(
                'overflow-x-hidden flex px-[15px] py-[13px] rounded-md bg-white justify-between focus-within:shadow-[0_0_4px_0_#009EF7_inset]',
                {
                  'pr-[40px] md:pr-[15px]': !isEditing,
                },
              )}
            >
              <div
                className={cn('flex items-center  overflow-x-hidden max-w-full', {
                  'max-w-11/12': !isEditing,
                })}
              >
                {domainName && (
                  <Controller
                    name='domainName'
                    control={control}
                    render={({ field }) => (
                      <>
                        <span
                          role='input'
                          key={String(isEditing)}
                          ref={inputRef}
                          contentEditable={isEditing}
                          onInput={e => {
                            const inputValue = e.currentTarget.textContent;
                            if (
                              !inputValue?.includes(' ') &&
                              inputValue &&
                              inputValue?.length <= 63
                            ) {
                              field.onChange(inputValue);
                            }
                          }}
                          onKeyDown={e => {
                            const isAlphanumeric = /^[0-9a-zA-Z]$/;
                            if (
                              Number(e.currentTarget.textContent?.length) >= 63 &&
                              !e.ctrlKey &&
                              isAlphanumeric.test(e.key)
                            )
                              e.preventDefault();

                            if (e.key === 'Enter' && !e.shiftKey) {
                              e.preventDefault();
                            }
                          }}
                          onFocus={e => {
                            const selection = window.getSelection();
                            const range = document.createRange();
                            range.selectNodeContents(e.currentTarget);
                            range.collapse(false);
                            selection?.removeAllRanges();
                            selection?.addRange(range);
                          }}
                          className='border-none outline-none pr-[1px]'
                        >
                          {domainName}
                        </span>
                        <span
                          className={cn(
                            'text-grey-600 whitespace-nowrap pointer-events-none block',
                            { hidden: domainNameValue.length > 35 },
                          )}
                        >
                          .{baseLink}
                        </span>
                      </>
                    )}
                  />
                )}
              </div>
              <div className='flex gap-[16px] absolute top-4 right-0 bg-white w-[30px]'>
                {!isEditing && (
                  <Button
                    type='button'
                    className='ml-1'
                    onClick={() => {
                      setIsEditing(true);
                      setTimeout(() => inputRef.current?.focus());
                    }}
                  >
                    <FaPen className='w-4 h-4 text-grey' />
                  </Button>
                )}
              </div>
            </div>
            <button className='hidden' ref={formButton}></button>
          </form>
          {domainNameValue.length > 35 && (
            <div className='flex flex-col text-start text-[12px] mt-2'>
              <span className='text-grey-400'>{t('myLinks.currentUrl')}</span>
              <span className='text-gray-900'>
                <span className='break-all'>{domainNameValue}</span>.<span>{baseLink}</span>
              </span>
              <span className='text-grey-400'>{t('myLinks.urlRecommend')}</span>
            </div>
          )}
          {(error as Error)?.message && (
            <FieldError error={(error as Error)?.message} className='inline-block md:hidden' />
          )}
          {errors?.domainName && (
            <FieldError error={errors?.domainName.message} className='inline-block md:hidden' />
          )}
        </div>
        {!isEditing ? (
          <CopyButton value={link} className='w-full' />
        ) : (
          <div className='flex w-full gap-2'>
            <Button
              onClick={() => {
                reset({ domainName });
                setIsEditing(false);
              }}
              variant={'muted'}
              className='
              w-full bg-grey-300 text-grey-600'
            >
              {t('common.discard')}
            </Button>
            <Button
              onClick={() => formButton.current?.click()}
              disabled={isLoading}
              className='
            w-full
            px-[18px] py-[13px] 
            rounded-[6px] bg-primary-light text-primary'
            >
              {t('common.save')}
            </Button>
          </div>
        )}
      </div>
      {(error as Error)?.message && (
        <FieldError error={(error as Error)?.message} className='hidden md:inline-block' />
      )}
      {errors?.domainName && (
        <FieldError error={errors?.domainName.message} className='hidden md:inline-block' />
      )}
    </div>
  );
};
