import React from 'react';

interface TutorialPageWrapperProps {
  children?: React.ReactNode;
}

export const TutorialPageWrapper: React.FC<TutorialPageWrapperProps> = ({
    children
}) => {
  return <main className='px-[15px] bg-white xl:bg-light md:px-[30px]'>{children}</main>;
};
