import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { MessengerButton, messengerButtonVariants } from '../MessengerButton/MessengerButton';
import { ExpertInterface, UserInterface } from 'src/store/user/user.slice';
import { cn } from 'src/common/utils/cn';
import { decreaseBrightness, decreaseButtonBrightness } from 'src/common/utils';
import { Dialog, DialogContent, DialogTrigger } from '../Dialog/Dialog';
import { Button } from '../Button/Button';
import { ThematicTemplate } from 'src/common/interfaces/thematicTemplate';
import { VariantProps } from 'class-variance-authority';
import './ProfilePreview.css';
import { RecursivePartial } from 'src/common/interfaces';
import './ProfilePreview.css';
import {
  getExpertWhatsappChatLink,
  getExpertMessengerChatLink,
  getExpertInstagramProfileLink,
} from 'src/common/utils/getExpertChatLink';
import Markdown from 'src/components/Markdown/Markdown';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
type BusinessCardProps = Pick<UserInterface, 'avatarUrl' | 'firstName' | 'lastName'> &
  RecursivePartial<
    Pick<ExpertInterface, 'brandLogoUrl' | 'description' | 'thematicTemplateConfig' | 'settings'>
  >;
type ProfilePreviewProps = Omit<React.ButtonHTMLAttributes<HTMLDivElement>, 'id'> &
  BusinessCardProps & {
    className?: string;
    channelsTitle?: string;
    withCreds?: boolean;
    messengerButtonVariants?: VariantProps<typeof messengerButtonVariants>['variant'];
    isPreview?: boolean;
    showAllPossibleVariants?: boolean;
    clientPreview?: boolean;
  };
export const ProfilePreview: React.FC<ProfilePreviewProps> = ({
  avatarUrl,
  brandLogoUrl,
  description,
  firstName,
  settings,
  lastName,
  children,
  thematicTemplateConfig,
  className,
  disabled,
  withCreds,
  channelsTitle = 'businessCard.chat',
  messengerButtonVariants = 'filled',
  isPreview,
  showAllPossibleVariants,
  clientPreview,
  ...props
}) => {
  const { t } = useTranslation();
  const nameRef = useRef<HTMLHeadingElement | null>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const commonMarkDownStyles: Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  > = {
    pre(props) {
      const { node, ...rest } = props;
      return <pre style={{ whiteSpace: 'pre-wrap' }} {...rest} />;
    },
    code(props) {
      const { node, ...rest } = props;
      return <code style={{ fontFamily: 'Open Sans, sans-serif', lineHeight: '22px' }} {...rest} />;
    },
  };

  const hasAtLeastOneChannel = useMemo(
    () =>
      settings?.whatsappSettings?.phone ||
      settings?.messengerSettings?.pageId ||
      settings?.instagramSettings?.username,
    [
      settings?.whatsappSettings?.phone,
      settings?.messengerSettings?.pageId,
      settings?.instagramSettings?.username,
    ],
  );
  useEffect(() => {
    if (descriptionRef.current) {
      const clone = descriptionRef.current.cloneNode(true) as HTMLElement;
      clone.style.width = 'auto';
      clone.style.wordBreak = 'break-all';
      clone.classList.remove('truncate-three', 'body', 'mt-[12px]');
      document.body.appendChild(clone);

      const isTruncated = descriptionRef.current.offsetHeight < clone.offsetHeight;
      document.body.removeChild(clone);
      setIsTruncated(isTruncated);
    }
  }, [description]);
  const usedLogoUrl = brandLogoUrl;
  const buttonColor = hasTemplateColor(thematicTemplateConfig?.thematicTemplate)
    ? thematicTemplateConfig?.thematicTemplate?.color
    : thematicTemplateConfig?.thematicTemplate?.color !== 'default'
    ? thematicTemplateConfig?.color || ''
    : '';
  const buttonBgColor =
    hasTemplateColor(thematicTemplateConfig?.thematicTemplate) || thematicTemplateConfig?.color
      ? thematicTemplateConfig?.whiteBackground
        ? decreaseButtonBrightness(
            thematicTemplateConfig?.thematicTemplate?.color ||
              (thematicTemplateConfig?.color as string),
            0.1,
          )
        : 'white'
      : '';
  const backgroundColor =
    (thematicTemplateConfig?.thematicTemplate?.color || buttonColor) &&
    thematicTemplateConfig?.thematicTemplate?.color !== 'default' &&
    !thematicTemplateConfig?.whiteBackground
      ? decreaseBrightness(
          thematicTemplateConfig?.thematicTemplate?.color || buttonColor || '',
          -0.2,
        )
      : 'white';
  return (
    <div
      className={cn(
        'w-[90vw] sm:w-[375px] rounded-[30px] overflow-hidden relative',
        {
          'h-full w-[100vw] sm:w-[375px] rounded-none sm:rounded-[30px] min-h-[100vh] sm:min-h-0':
            clientPreview,
          'shadow-[0_0_40px_0_rgba(0,0,0,0.1)]': !isPreview,
        },
        className,
      )}
    >
      <div
        className={cn(`h-[180px] bg-white overflow-hidden`, {
          'bg-[#E5F5FE]': isPreview,
        })}
        style={{
          ...(!isPreview ? { backgroundColor } : {}),
        }}
      >
        {usedLogoUrl ? (
          <img src={usedLogoUrl} className='w-full h-full object-cover' alt='Avatar' />
        ) : (
          isPreview && (
            <div className='text-sm text-grey-900/30 mt-12 mx-auto'>
              {t('onboarding.profilePreview.cover')}
            </div>
          )
        )}
      </div>
      <div
        className={cn(
          'relative bg-white',
          {
            'bg-opacity-25': !!thematicTemplateConfig?.thematicTemplate?.color,
            'h-[calc(100vh-180px)] sm:h-full': clientPreview,
          },
          className,
        )}
        style={{
          backgroundColor,
        }}
      >
        <div className='h-full w-full top-0 left-0 right-0 bottom-0 flex flex-col items-center'>
          <div
            className={`absolute -top-[85px]  w-[149px] h-[149px] sm:w-[170px] sm:h-[170px] rounded-full px-[3px] py-[3px]`}
            style={{
              backgroundColor: backgroundColor || 'white',
            }}
          >
            <div className='w-[143px] h-[143px] sm:w-[164px] sm:h-[164px] rounded-full overflow-hidden'>
              {!avatarUrl ? (
                <FaUserCircle className='w-[143px] h-[143px] sm:w-[164px] sm:h-[164px] text-[#E5F5FE]' />
              ) : (
                <img
                  src={avatarUrl}
                  className='w-[143px] h-[143px] sm:w-[164px] sm:h-[164px] object-cover'
                  alt='Avatar'
                />
              )}
            </div>
          </div>
          <div className='text-center mt-[85px]'>
            <h1
              className={cn('header-1 mt-[22px] break-all max-h-full px-[30px]', {
                'header-2':
                  nameRef.current &&
                  nameRef.current.textContent &&
                  nameRef.current.textContent?.length > 19,
                'header-3':
                  nameRef.current &&
                  nameRef.current.textContent &&
                  nameRef.current.textContent?.length > 28,
              })}
              ref={nameRef}
            >
              {firstName || lastName ? `${firstName || ''} ${lastName || ''}` : 'Your Name'}
            </h1>
            <div
              className='body mt-[12px] max-w-[240px] truncate-three break-words mx-auto'
              ref={descriptionRef}
            >
              {description ? (
                <Markdown
                  components={{
                    p(props) {
                      const { node, ...rest } = props;
                      return <p style={{ display: 'inline' }} {...rest} />;
                    },
                    ...commonMarkDownStyles,
                  }}
                >
                  {description}
                </Markdown>
              ) : (
                <span className='text-grey-800'>
                  {t('common.onboardingStatus.desktop.descriptionStatus')}
                </span>
              )}
            </div>
          </div>
          <div className='mt-6 w-full px-[38px]'>
            <Dialog>
              <DialogTrigger asChild>
                {isTruncated && description && (
                  <Button
                    variant={'muted'}
                    className={cn('w-full text-primary')}
                    style={{
                      color: buttonColor,
                      backgroundColor: buttonBgColor,
                    }}
                  >
                    {t('businessCard.more')}
                  </Button>
                )}
              </DialogTrigger>
              <DialogContent className='rounded-[24px]'>
                <div className='flex flex-col gap-4 text-grey-900 whitespace-pre-wrap'>
                  <h1 className='header-1 text-center'>{t('businessCard.about')}</h1>
                  <span className='break-words mb-[30px]'>
                    {description && (
                      <Markdown
                        components={{
                          ...commonMarkDownStyles,
                        }}
                      >
                        {description}
                      </Markdown>
                    )}
                  </span>
                </div>
              </DialogContent>
            </Dialog>
            <div className='flex flex-col mt-[8px] mb-12 text-center gap-[44px] justify-between items-center'>
              <div className='flex flex-col gap-4 w-full'>
                {hasAtLeastOneChannel ? (
                  <h2 className='font-semibold'>
                    {!isPreview ? t(channelsTitle) : 'Your connsultancy channels'}
                  </h2>
                ) : null}
                <MessengerButton
                  className={cn({
                    hidden: !settings?.whatsappSettings?.phone && !showAllPossibleVariants,
                  })}
                  app='whatsapp'
                  formNoValidate
                  variant={settings?.whatsappSettings?.phone ? 'filled' : 'default'}
                  onClick={() => {
                    if (!disabled && settings?.whatsappSettings?.phone) {
                      const link = getExpertWhatsappChatLink(
                        settings?.whatsappSettings?.phone.replace('+', ''),
                      );
                      window.open(link, '_blank');
                    }
                  }}
                  templateColor={buttonColor}
                >
                  Whatsapp
                </MessengerButton>
                <MessengerButton
                  className={cn({
                    hidden: !settings?.messengerSettings?.pageId && !showAllPossibleVariants,
                  })}
                  app='messenger'
                  formNoValidate
                  variant={settings?.messengerSettings?.pageId ? 'filled' : 'default'}
                  onClick={() => {
                    if (!disabled && settings?.messengerSettings?.pageId) {
                      const link = getExpertMessengerChatLink(settings?.messengerSettings?.pageId);
                      window.open(link, '_blank');
                    }
                  }}
                  templateColor={buttonColor}
                >
                  Messenger
                </MessengerButton>
                <MessengerButton
                  className={cn('border-black-400', {
                    hidden: !settings?.instagramSettings?.username && !showAllPossibleVariants,
                  })}
                  app='instagram'
                  formNoValidate
                  variant={settings?.instagramSettings?.username ? 'filled' : 'default'}
                  onClick={() => {
                    if (!disabled && settings?.instagramSettings?.username) {
                      const link = getExpertInstagramProfileLink(
                        settings?.instagramSettings?.username,
                      );
                      window.open(link, '_blank');
                    }
                  }}
                  templateColor={buttonColor}
                >
                  Instagram
                </MessengerButton>
                <MessengerButton
                  className={cn('border-black-400', {
                    hidden: !settings?.telegramSettings?.botName && !showAllPossibleVariants,
                  })}
                  app='telegram'
                  formNoValidate
                  variant={settings?.telegramSettings?.botName ? 'filled' : 'default'}
                  onClick={() => {
                    if (!disabled && settings?.telegramSettings?.botName) {
                      window.open(`https://t.me/${settings?.telegramSettings?.botName}`, '_blank');
                    }
                  }}
                  templateColor={buttonColor}
                >
                  Telegram
                </MessengerButton>
                {children}
              </div>
              {withCreds && (
                <span className='text-muted text-center'>
                  Powered by{' '}
                  <a
                    href={`${process.env.REACT_APP_ANAMATA_HOME}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      color: buttonColor,
                      fontWeight: 'bold',
                    }}
                  >
                    Anamata
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const hasTemplateColor = (thematicTemplate?: Partial<ThematicTemplate>) => {
  return thematicTemplate?.color && thematicTemplate.color !== 'default';
};
export type { ProfilePreviewProps, BusinessCardProps };
