import React from 'react';
import { chatMessageStyles } from './ChatMessage.config';

import dayjs from 'dayjs';
import { DatePlaceholder } from 'src/components/DatePlaceholder';
import {
  ChatMessage as ChatMessageT,
  MessageStatus,
  MessengerType,
  UploadedFile,
} from 'src/common/interfaces';
import { cn } from 'src/common/utils';

import { PhotoView, PhotoProvider } from 'react-photo-view';
import Markdown from 'src/components/Markdown/Markdown';
import { whatsappMarkdownSettings } from 'src/common/configs/whatsapp-markdown';
import { groupBy } from 'lodash';
import { FilePlaceholder } from '../FilePlaceholder/FilePlaceholder';

export interface ChatMessageProps
  extends Partial<Pick<ChatMessageT, 'messengerType' | 'attachments'>> {
  message: React.ReactChild;
  date?: string | Date;
  status?: MessageStatus;
  sender?: 'client' | 'expert' | 'assistant' | 'user';
  actionButtons?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  textClassName?: string;
  link?: string;
}

type GroupedAttachments = Partial<{
  image: UploadedFile[];
  audio: UploadedFile[];
  file: UploadedFile[];
}>;

const ChatMessage = ({
  message,
  date,
  sender = 'assistant',
  attachments,
  children,
  className,
  textClassName,
  actionButtons,
  messengerType,
  link,
}: ChatMessageProps) => {
  const styles = chatMessageStyles[sender];

  const groupedAttachments = groupBy(attachments, 'type') as GroupedAttachments;

  return (
    <div className={cn(`w-full flex ${styles.placeholder}`)}>
      <div className={cn(`max-w-3/4 p-4 rounded-md ${styles.messageWrapper}`, className)}>
        {groupedAttachments?.image && <ImagesCarousel attachments={groupedAttachments.image} />}
        {groupedAttachments?.audio && <audio controls src={groupedAttachments?.audio?.[0].src} />}
        {groupedAttachments?.file && (
          <Files
            files={groupedAttachments.file}
            className={cn({ 'text-white': sender === 'expert' })}
          />
        )}
        <div
          className={cn(
            `mb-2 font-body text-base font-normal leading-6 whitespace-pre-line break-words ${styles.text}`,
            textClassName,
          )}
        >
          {message &&
            !link &&
            (messengerType !== MessengerType.INSTAGRAM || typeof message === 'string' ? (
              <Markdown components={whatsappMarkdownSettings}>{message as string}</Markdown>
            ) : (
              message
            ))}
          {link && (
            <img src={link} className='w-[190px] h-[190px] object-cover' alt='Avatar' />
          )}
        </div>
        {date && sender !== 'assistant' && (
          <div className={`text-right ${styles.dateText}`}>
            <DatePlaceholder date={date} className='text-[currentColor]' />
          </div>
        )}
        {actionButtons}
      </div>
      {children}
    </div>
  );
};

const ImagesCarousel = ({ attachments }: { attachments: ChatMessageT['attachments'] }) => {
  return (
    <PhotoProvider maskOpacity={0.3}>
      <div className='flex gap-2 w-full flex-wrap'>
        {attachments?.map(attachment => (
          <PhotoView key={attachment.src} src={attachment.src}>
            <img
              src={attachment.src}
              alt={attachment.alt || `img `}
              className={`cursor-pointer max-h-[400px]`}
            />
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  );
};

const Files = ({ files, className }: { files: UploadedFile[]; className?: string }) => {
  return (
    <div className={cn('relative flex items-center justify-between gap-10', className)}>
      {files.map(file => (
        <FilePlaceholder file={file} key={file.src} />
      ))}
    </div>
  );
};

export default ChatMessage;
