import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { ROUTES } from 'src/router/routes';
import { cn } from 'src/common/utils/cn';

const MobileBackButton = ({
  className,
  to = `/${ROUTES.DASHBOARD}`,
}: {
  to?: string;
  className?: string;
}) => {
  return (
    <Link to={to} className={cn('md:hidden', className)}>
      <FaChevronLeft className='w-[22px] h-[22px] text-muted' />
    </Link>
  );
};

export { MobileBackButton };
