import React, { type RefObject, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextInput } from 'src/ui/TextInput/TextInput';
import { Textarea } from 'src/ui/TextInput/TextInput';
import { useForm, Controller } from 'react-hook-form';
import { AppStateType } from 'src/store';
import { profileSchema } from 'src/common/utils/schema/profile.schema';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { nextStep } from 'src/store/onboarding/onboarding.slice';
import { updateUser } from 'src/store/user/user.actions';

type Inputs = z.infer<typeof profileSchema>;

const AboutYouForm = ({
  aboutButtonRef,
  handleSubmit,
  reset,
  control,
  setIsLoading,
  formState: { errors },
}: {
  aboutButtonRef: RefObject<HTMLButtonElement>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
} & ReturnType<typeof useForm<Inputs>>) => {
  const { user } = useSelector((state: AppStateType) => state.user);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    reset({
      ...user,
      avatarUrl: user?.avatarUrl,
      description: user?.expert?.description,
    });
  }, [user]);

  const onSubmit = (vals: Inputs) => {
    setIsLoading(true);

    dispatch(
      updateUser({
        userData: {
          ...user,
          ...vals,
          expert: {
            ...user?.expert,
            userId: user?.id!,
            description: vals.description,
          },
        },
      }),
    );

    setIsLoading(false);
    dispatch(nextStep(1));
  };

  return (
    <form className='flex flex-col gap-8' onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className='flex gap-[16px] md:flex-row flex-col'>
          <div className='w-full'>
            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <TextInput
                  className='h-[50px]'
                  error={errors.firstName?.message}
                  placeholder={t('common.expert.firstName')}
                  type='input'
                  {...field}
                />
              )}
            />
          </div>
          <div className='w-full'>
            <Controller
              name='lastName'
              control={control}
              render={({ field }) => (
                <TextInput
                  className='h-[50px]'
                  error={errors.lastName?.message}
                  placeholder={t('common.expert.lastName')}
                  type='input'
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className='mt-[16px] w-full'>
          <Controller
            name='description'
            control={control}
            render={({ field }) => (
              <Textarea
                minHeight={400}
                placeholder={t('common.expert.description')}
                limit={1000}
                {...field}
                className='h-[400px]'
                error={errors.description?.message}
              />
            )}
          />
        </div>
      </div>
      <button className='hidden' ref={aboutButtonRef} />
    </form>
  );
};

export { AboutYouForm };
