import React, { useState } from 'react';
import { Button } from 'src/ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { MyLinksTab } from 'src/components/MyLinksTab';
import { useMutation } from '@tanstack/react-query';
import { updateUserLocaly } from 'src/store/user/user.slice';
import { updateUser } from 'src/common/api/user';
import { Logo } from 'src/ui/Logo/Logo';
import { ProfilePreviewCard } from 'src/pages/My-links/MyLinks.page';
import { PreviewModal } from 'src/ui/Modal/ProfileModal';
import { OnboardingSteps } from 'src/common/enums/onboardingSteps.enum';
import { setStep } from 'src/store/onboarding/onboarding.slice';

interface AboutYouLeftProps {}

export const CongratulationsLeft: React.FC<AboutYouLeftProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { user } = useSelector((state: AppStateType) => state.user);
  const navigate = useNavigate();

  const { mutate: completeOnboarding } = useMutation({
    mutationFn: () => {
      return updateUser({ hadUserOnboarding: true, id: user?.id, isDomainCompleted: true });
    },
    onSuccess: data => {
      if (data) {
        dispatch(updateUserLocaly(data));
      }
      navigate(`/${ROUTES.CHATS}`);
    },
  });

  const handleSubmit = () => {
    completeOnboarding();
  };

  const handleConnect = () => {
    dispatch(setStep(OnboardingSteps.CHANNELS));
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className='relative flex flex-col h-full'>
      <div className='flex flex-col items-center justify-center max-w-[600px] mx-[auto] my-[auto] grow'>
        <div className='flex flex-col items-center text-center gap-[16px] justify-center'>
          <div className='text-[64px]'>🎉</div>
          <h1 className='text-[24px] font-bold'>{t('onboarding.finish.header')}</h1>
          <div className='body'>{t('onboarding.finish.subheader')}</div>
          <Button
            className='xl:hidden text-400 leading-[22px] text-[14px] text-primary p-0 -mt-[8px]'
            onClick={() => setIsOpen(true)}
          >
            See my online business card
          </Button>
        </div>
        <div className='mt-[12px] w-full'>
          <MyLinksTab domainName={user?.expert?.domainName} />
        </div>
        <div className='md:mt-[30px] mt-[24px] text-[14px] leading-[22px] text-grey-900 text-center'>
          {t('onboarding.finish.channels')}
        </div>
        <div className='md:mt-[30px] mt-[24px] flex-col md:flex-row flex justify-between gap-[15px] w-full'>
          <Button
            variant='primary'
            className='w-full md:w-6/12 mx-auto'
            onClick={() => handleConnect()}
          >
            {t('onboarding.finish.more')}
          </Button>
          <Button
            variant='primary_light'
            className='w-full md:w-6/12 mx-auto'
            onClick={() => handleSubmit()}
          >
            {t('onboarding.finish.finishButton')}
          </Button>
        </div>
      </div>
      <PreviewModal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        backgroundImage={user?.expert?.brandLogoUrl}
      >
        <ProfilePreviewCard user={user} />
      </PreviewModal>
    </div>
  );
};
