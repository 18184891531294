import React, { useState, useMemo, useEffect } from 'react';
import { CardPicker } from 'src/ui/CardPicker/CardPicker';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'src/store';
import { useThematicTemplateStore } from 'src/store/expertise/useThematicTemplateStore';
import { ThematicTemplateConfig } from 'src/common/interfaces/thematicTemplate';
import { CheckBox } from 'src/ui/CheckBox/CheckBox';
import { ColorPicker } from 'src/ui/ColorPicker/ColorPicker';
import { customColorTemplateId, defaultThemplateId } from 'src/common/configs/thematic-templates';
import { RecursivePartial } from 'src/common/interfaces';
import { useOnboardingContext } from 'src/context/Onboarding.context';
import { useAiAssistantStore } from 'src/store/onboarding/ai-assistant.store';
import { Button } from 'src/ui/Button/Button';
import { updateUserLocaly } from 'src/store/user/user.slice';

interface AiThematicTemplateProps {}

export const AiThematicTemplate: React.FC<AiThematicTemplateProps> = () => {
  const { data: thematicTemplates } = useThematicTemplateStore();
  const { user } = useSelector((state: AppStateType) => state.user);
  const { updateTemplateViaAI } = useAiAssistantStore();

  const { updateProfilePreview } = useOnboardingContext();
  const dispatch = useDispatch();

  const [configs, setConfigs] = useState<RecursivePartial<ThematicTemplateConfig>>({
    thematicTemplate: user?.expert?.thematicTemplateConfig?.thematicTemplate,
    whiteBackground: user?.expert?.thematicTemplateConfig?.whiteBackground,
    color: user?.expert?.thematicTemplateConfig?.color,
  });

  useEffect(() => {
    setConfigs({
      thematicTemplate: user?.expert?.thematicTemplateConfig?.thematicTemplate,
      whiteBackground: user?.expert?.thematicTemplateConfig?.whiteBackground,
      color: user?.expert?.thematicTemplateConfig?.color,
    });
  }, [user]);

  const { mutate: updateUserBranding } = updateTemplateViaAI;

  const onConfigsChange = (key: 'thematicTemplate' | 'whiteBackground' | 'color', value: any) => {
    const newConfig = {
      ...configs,
      [key]: value,
    };

    setConfigs(newConfig);

    updateProfilePreview({
      thematicTemplateConfig: {
        thematicTemplate: thematicTemplates?.find(
          t => t.thematicTemplateId === newConfig.thematicTemplate?.thematicTemplateId,
        ),
        whiteBackground: newConfig.whiteBackground,
        color: newConfig.color,
        wasChangedByUser: true,
        wasIntentionallyChangedByUser: true,
      },
    } as any);
  };

  const cards = useMemo(() => {
    return [
      ...(thematicTemplates?.map(({ thematicTemplateId, translation }) => ({
        value: thematicTemplateId,
        label: translation,
      })) ?? []),
      { value: customColorTemplateId, label: 'common.thematicTemplate.otherColor' },
    ];
  }, [thematicTemplates]);

  const onSubmit = () => {
    const isCustomColor = configs.thematicTemplate?.thematicTemplateId === customColorTemplateId;

    updateUserBranding({
      thematicTemplateId: isCustomColor ? null : configs.thematicTemplate?.thematicTemplateId,
      color: isCustomColor ? configs.thematicTemplate?.color : null,
      whiteBackground: configs.whiteBackground,
      wasChangedByUser: true,
      wasIntentionallyChangedByUser: true,
    });
  };

  return (
    <div className='flex flex-col items-center gap-[15px]'>
      <div className='flex justify-end'>
        <div className='max-w-[80%]'>
          <CardPicker
            headerText=''
            cards={cards}
            value={configs.thematicTemplate?.thematicTemplateId || customColorTemplateId}
            onChange={value => {
              const newValue = thematicTemplates?.find(
                template => template.thematicTemplateId === value,
              ) || {
                thematicTemplateId: customColorTemplateId,
              };

              'logoUrl' in newValue &&
                dispatch(
                  updateUserLocaly({
                    id: user?.id!,
                    expert: {
                      userId: user?.id!,
                      brandLogoUrl: newValue.logoUrl,
                      thematicTemplateConfig: {
                        thematicTemplate: newValue,
                      },
                      thematicTemplateConfigId: newValue.thematicTemplateId,
                    },
                  }),
                );
              onConfigsChange('thematicTemplate', newValue);
            }}
          />
          {configs.thematicTemplate?.thematicTemplateId !== defaultThemplateId && (
            <div className='flex gap-2 items-center my-4'>
              <CheckBox
                id='whiteBackground'
                checked={configs.whiteBackground}
                onCheckedChange={val =>
                  onConfigsChange('whiteBackground', typeof val == 'boolean' ? val : false)
                }
              />
              <label htmlFor='whiteBackground' className='text-grey-900'>
                Use white background
              </label>
            </div>
          )}
          {configs.thematicTemplate?.thematicTemplateId === customColorTemplateId && (
            <ColorPicker
              className='mt-4'
              value={configs.color}
              onChange={color => onConfigsChange('color', color)}
              colors={[
                '#A4262C',
                '#ED780D',
                '#867365',
                '#407855',
                '#038387',
                '#0078D4',
                '#40587C',
                '#8764B8',
              ]}
            />
          )}
        </div>
      </div>
      <Button variant='primary' onClick={onSubmit}>
        Confirm
      </Button>
    </div>
  );
};
