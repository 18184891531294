import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import MenuBarItem from '../MenuBarItem/MenuBarItem';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/common/utils/cn';
import { UserMenu } from 'src/components/UserMenu';
import { Link, useLocation } from 'react-router-dom';
import { useOnboardingStatus, useScreenDetect } from 'src/common/hooks';
import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
} from '../DropdownMenu/DropdownMenu';
import { ROUTES } from 'src/router/routes';
import { UserInterface } from 'src/store/user/user.slice';
import { OnboardingMenu } from 'src/components/OnboardingMenu';
import { Button } from '../Button/Button';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { FaArrowRight, FaArrowUpRightFromSquare } from 'react-icons/fa6';

interface MenuBarProps {
  username: string;
  userImage?: UserInterface['avatarUrl'];
  email?: string;
  className?: string;
  navigationItems: {
    tPath: string;
    icon: IconType;
    route: string;
    counter?: number;
    component?: React.ReactNode;
  }[];
}

const MenuBar = ({ userImage, username, navigationItems, email, className }: MenuBarProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn('bg-white', className)}>
      <div
        className={cn(
          'max-w-[100px] h-[100vh] p-[20px] pb-[30px] bg-white flex flex-col justify-between sticky top-0',
          className,
        )}
      >
        <div className='flex flex-col gap-[20px]'>
          <UserMenu userImage={userImage} username={username} email={email}>
            <SharedMenuGroup />
          </UserMenu>
          <div>
            {navigationItems.map(({ tPath, icon, route, counter, component }, idx) => (
              <div className='py-[15px]' key={idx}>
                <MenuBarItem
                  icon={icon}
                  route={route}
                  text={t(tPath)}
                  counter={counter}
                  component={component}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-[16px] items-center'>
          <OnboardingMenu />
        </div>
      </div>
    </div>
  );
};

const routesWithoutMenuBar = [
  ROUTES.PROFILE,
  ROUTES.BUSINESS_CARD,
  ROUTES.SUBSCRIPTIONS,
  ROUTES.OFFER,
  ROUTES.PAYMENTS,
  ROUTES.NOTIFICATIONS,
  ROUTES.COMMUNICATION_CHANNELS,
  ROUTES.AI_HELPER_SETTINGS,
];

const MobileMenuBar = ({
  navigationItems,
  className,
  ignoreRoutes,
}: MenuBarProps & {
  ignoreRoutes?: boolean;
}) => {
  const { pathname } = useLocation();

  const { status, t, handleBackToOnboarding, isCompleted, completedAmount } = useOnboardingStatus();

  const handleBack = () => {
    handleBackToOnboarding();
  };

  const { isTargetScreen } = useScreenDetect('mobile');

  if (
    (/^\/messages\/[^\/]+(\/.*)?$/.test(pathname) && isTargetScreen) ||
    (routesWithoutMenuBar.some(r => pathname.startsWith('/' + r)) && !ignoreRoutes)
  ) {
    return null;
  }

  return (
    <div
      className={cn(
        'w-[100vw] pb-safe bg-white border-r border-grey flex justify-center items-center z-20 relative',
        className,
      )}
    >
      <div
        className='h-[25px] w-full absolute -top-[25px]'
        style={{
          background: isCompleted
            ? 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))'
            : 'white',
        }}
      ></div>
      {!isCompleted ? (
        <div className='bg-warning-light p-[15px] pt-[12px] pb-[17px] absolute bottom-[120%] w-full'>
          <div className='flex justify-between items-center text-[14px]'>
            <p className='font-normal'>{t('common.onboardingStatus.mobile.title')}</p>
            <Button onClick={() => handleBack()} className='text-[14px] text-primary'>
              {t('common.onboardingStatus.mobile.button')}
              <FaArrowRight />
            </Button>
          </div>
          <ProgressBar
            total={Object.values(status).length}
            completed={completedAmount}
            className='mt-[5px]'
          />
        </div>
      ) : null}
      <div className='flex items-center w-full max-w-[320px]'>
        {navigationItems.map(({ tPath, icon, route, counter }, idx) => (
          <div className='w-1/3 h-[95px] flex flex-col justify-center pb-[15px]' key={idx}>
            <MenuBarItem icon={icon} route={route} text={t(tPath)} counter={counter} />
          </div>
        ))}
      </div>
    </div>
  );
};

const SharedMenuGroup = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const route = useMemo(() => pathname.split('/')[1], [pathname]);

  return (
    <DropdownMenuGroup className='px-2'>
      <DropdownMenuLabel>{t('tabletMenu.accountSettings')}</DropdownMenuLabel>
      <DropdownMenuItem active={route === ROUTES.PROFILE} className={'body !text-[14px]'} asChild>
        <Link to={`/${ROUTES.PROFILE}`}>{t('tabletMenu.profile')}</Link>
      </DropdownMenuItem>
      <DropdownMenuItem
        active={route === ROUTES.NOTIFICATIONS}
        className='body !text-[14px]'
        asChild
      >
        <Link to={`/${ROUTES.NOTIFICATIONS}`}>{t('tabletMenu.notifications')}</Link>
      </DropdownMenuItem>
      <DropdownMenuItem active={false} className='body !text-[14px]'>
        {t('tabletMenu.affiliateProgram')}
      </DropdownMenuItem>
      <DropdownMenuItem active={false} className='body !text-[14px]'>
        <div className='flex gap-[16px] items-center'>
          <a href={`mailto:${process.env.REACT_APP_TARGET_EMAIL}`}>{t('tabletMenu.emailUs')}</a>{' '}
          <FaArrowUpRightFromSquare className='text-grey-400' />
        </div>
      </DropdownMenuItem>
    </DropdownMenuGroup>
  );
};

export type { MenuBarProps };

export { MenuBar, MobileMenuBar, SharedMenuGroup };
