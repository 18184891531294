import * as z from 'zod';

const attachmentsMessageSchema = z.object({
  files: z
    .unknown()
    .refine(val => {
      if (!Array.isArray(val)) return false;
      if (val.some(file => !(file instanceof File))) return false;
      return true;
    }, 'Must be an array of File')
    .optional()
    .nullable()
    .default(null),
  body: z.string().optional(),
});

export { attachmentsMessageSchema };
